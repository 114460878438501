import React from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Switch,
} from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faGear,
  faSignOutAlt,
  faToggleOff,
  faUser,
  faListCheck,
  faTextWidth,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { finishLoading, setError, startLoading } from '../state/loadingSlice';
import { logoutUser } from '../api/taskApi';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { setShortTitles, setHideCompleted } from '../state/settingsSlice';
import { Tooltip } from 'react-tooltip';
import logout from '../utils/logout';

const SettingsMenu = () => {
  const { loading } = useSelector((state) => state.loading);
  const { identifier } = useSelector((state) => state.keys);
  const { shortTitles, hideCompleted } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleShortenTitles = async (e) => {
    e.preventDefault();
    dispatch(startLoading('Updating settings...'));
    dispatch(setShortTitles(!shortTitles));
    dispatch(finishLoading());
  };

  const handleHideCompleted = async (e) => {
    e.preventDefault();
    dispatch(startLoading('Updating settings...'));
    dispatch(setHideCompleted(!hideCompleted));
    dispatch(finishLoading());
  };

  const handleSignOutClick = async (e) => {
    e.preventDefault();
    logout(dispatch, navigate, queryClient);
  };
  return (
    <Menu>
      <MenuButton className='text-stone-500  data-[active]:text-stone-800 hover:text-stone-800'>
        <FontAwesomeIcon
          icon={faGear}
          className=' w-5 h-5 justify-center flex   '
        />
      </MenuButton>
      <MenuItems
        anchor='top'
        className={`bg-stone-400 text-sm shadow-md rounded-sm text-white flex flex-col items-start`}
      >
        <MenuItem
          className='flex items-center w-full p-1 px-2 bg-stone-300/50 italic data-[focus]:bg-stone-500
        border-b border-stone-600'
        >
          <button onClick={() => navigate('/account')}>
            <FontAwesomeIcon icon={faUser} className='mr-1' />{' '}
            <span>{identifier}</span>
          </button>
        </MenuItem>

        <MenuItem className='flex items-center w-full p-1 px-2 bg-stone-300/50 data-[focus]:bg-stone-500'>
          <div
            className='flex items-center justify-between w-full cursor-pointer'
            onClick={(e) => handleShortenTitles(e)}
            disabled={loading}
          >
            <div className='flex items-center'>
              <FontAwesomeIcon icon={faTextWidth} className='mr-1' />
              <span>Short Titles</span>
            </div>
            <Switch
              checked={shortTitles}
              className='ml-2 group inline-flex h-5 w-8 items-center rounded-full bg-gray-200 transition data-[checked]:bg-emerald-600'
              onClick={(e) => handleShortenTitles(e)}
            >
              <span className='size-3 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-4' />
            </Switch>
          </div>
        </MenuItem>

        <MenuItem className='flex items-center w-full p-1 px-2 bg-stone-300/50 data-[focus]:bg-stone-500'>
          <div
            className='flex items-center justify-between w-full cursor-pointer'
            onClick={(e) => handleHideCompleted(e)}
            data-tooltip-id='menu-tooltip'
            data-tooltip-content='Hides also cancelled tasks.'
          >
            <div className='flex items-center'>
              <FontAwesomeIcon icon={faListCheck} className='mr-1' />
              <span>Hide Completed</span>
            </div>
            <Switch
              checked={hideCompleted}
              className='ml-2 group inline-flex h-5 w-8 items-center rounded-full bg-gray-200 transition data-[checked]:bg-emerald-600'
              onClick={(e) => handleHideCompleted(e)}
            >
              <span className='size-3 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-4' />
            </Switch>
          </div>
        </MenuItem>

        <MenuItem className='flex items-center w-full p-1 px-2 bg-stone-400 data-[focus]:bg-stone-500 border-t border-stone-600'>
          <button onClick={(e) => handleSignOutClick(e)} disabled={loading}>
            <FontAwesomeIcon icon={faSignOutAlt} className='mr-1' />{' '}
            <span>Logout</span>
          </button>
        </MenuItem>
      </MenuItems>
      <Tooltip
        id='menu-tooltip'
        data-tooltip-delay-show={1000}
        className='z-10'
      />
    </Menu>
  );
};

export default SettingsMenu;
