import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    loading: false,
    message: '',
    lastMessages: [] as { message: string; success: boolean; date: number }[], // Define the type here
    error: null,
  },
  reducers: {
    startLoading: (state, action) => {
      const message = action.payload || '';
      state.error = null;
      state.loading = true;
      state.message = message;
      //push last message at the end and only keep last 5 in lastMessages
      state.lastMessages = [
        ...state.lastMessages,
        { message: message, success: true, date: new Date().getTime() },
      ].slice(-5);
    },

    finishLoading: (state, action: PayloadAction<string | undefined>) => {
      const message = action?.payload || '';
      state.loading = false;
      state.message = message;
    },

    setError: (state, action) => {
      const { message, error = {} } = action.payload;
      state.loading = false;
      const finalMessage =
        error && error.response ? error.response.data?.error : message;

      state.error = finalMessage;
      state.lastMessages = [
        ...state.lastMessages,
        { message: finalMessage, success: false, date: new Date().getTime() },
      ].slice(-5);
      // console.error(message + ' - ' + JSON.stringify(error));
    },
  },
});

export const { startLoading, finishLoading, setError } = loadingSlice.actions;

export default loadingSlice.reducer;
