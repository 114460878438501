const getOS = () => {
  const platform = navigator.platform.toLowerCase();
  const userAgent = navigator.userAgent.toLowerCase();

  if (platform.includes('mac')) return 'mac';
  if (platform.includes('win')) return 'windows';
  if (platform.includes('linux')) return 'linux';
  if (/android|iphone|ipad|ipod/.test(userAgent)) return 'mobile';

  return 'unknown';
};

const shortcutLabel = (key: string) => {
  const os = getOS();

  const shortcutKey = os === 'mac' ? '⌘' : 'Ctrl';

  return (
    <span>
      {shortcutKey}+{key}
    </span>
  );
};

const getGenericKey = (key: string) => {
  switch (key) {
    case 'ArrowUp':
      return '↑';
    case 'ArrowDown':
      return '↓';
    case 'ArrowLeft':
      return '←';
    case 'ArrowRight':
      return '→';
    case 'Enter':
      return '↵ Enter';
    case 'Escape':
      return 'Esc';
    default:
      return key;
  }
};
const ShortcutLabel = ({
  keyShortcut,
  genericKey,
}: {
  keyShortcut?: string;
  genericKey?: string;
}) => {
  return (
    <span className='bg-stone-200 pl-1 pr-1 text-xs rounded-md border border-stone-400 ml-1'>
      {keyShortcut
        ? shortcutLabel(keyShortcut)
        : genericKey
        ? getGenericKey(genericKey)
        : ''}
    </span>
  );
};

export default ShortcutLabel;
