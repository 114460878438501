import confetti from 'canvas-confetti';

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

function celebrateDefault(e) {
  confetti({
    angle: randomInRange(30, 120),
    spread: randomInRange(50, 100),
    particleCount: randomInRange(50, 100),
    origin: {
      x: e.clientX / window.innerWidth,
      y: e.clientY / window.innerHeight,
    },
    startVelocity: 20,
  });
}

function celebrateFireworks(e) {
  let duration = 10 * 1000;
  let animationEnd = Date.now() + duration;
  let defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
  let interval = setInterval(function () {
    let timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    let particleCount = 50 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    });
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    });
  }, 250);
}

function celebrate(e, scheduled_type) {
  if (scheduled_type === 'Y') {
    celebrateFireworks(e);
  } else {
    celebrateDefault(e);
  }
}

export default celebrate;
