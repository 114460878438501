import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSearchMenu } from '../../state/searchMenuSlice';
import { openModal } from '../../state/modalSlice';
import Task from '../Task/Task';
import { Tooltip } from 'react-tooltip';
import ShortcutLabel from '../Helpers/ShortcutLabel';
import { RootState } from '../../state/store';

const SearchMenu = () => {
  const searchMenuData = useSelector((state: RootState) => state.searchMenu);
  const tasksData = useSelector((state: RootState) => state.tasks);
  const tasksDataArray = useMemo(() => Object.values(tasksData), [tasksData]);
  const isOpen = searchMenuData.isOpen;
  const [searchText, setSearchText] = useState('');
  const [searchTextWords, setSearchTextWords] = useState<string[]>([]); // Specify the type as string[]
  const searchRef = useRef<HTMLInputElement>(null); // Specify the type as HTMLInputElement

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen && searchRef.current) {
      searchRef.current.focus(); // Focus the input when the menu opens
    }
  }, [isOpen]); // The effect runs every time `isOpen` changes

  useEffect(() => {
    if (searchText.trim() === '') {
      setSearchTextWords([]);
      return;
    }
    if (searchText.trim() === '"' || searchText.trim() === '""') {
      return;
    }

    console.log({ searchText });

    setSearchTextWords(
      searchText
        ?.toLowerCase()
        .match(/"[^"]+"|\S+/g)
        ?.map((word) => word.replace(/"/g, '')) || [] // Handle null case
    );
  }, [searchText]);

  const handleSearchChange = (value: string) => {
    setSearchText(value);
  };

  const filteredAndRankedTasks = useMemo(() => {
    if (!searchText) return [];
    // if (searchText.length < 3) return [];
    if (searchText.trim() === '"') return [];

    return tasksDataArray
      .map((task) => {
        const title = task.title.toLowerCase();
        const description = task.description?.toLowerCase() || '';
        const subtasksCount = task.children?.length;

        let matchCount = 0;
        let titleWordCount = 0;
        let descriptionWordCount = 0;
        let exactPhraseMatchTitle = false;
        let exactPhraseMatchDescription = false;

        // Only keep tasks where every search word is found in either title or description
        const allWordsMatch = searchTextWords.every(
          (word) => title.includes(word) || description.includes(word)
        );

        // Conteaza restul de calcule doar daca contine toate cuvintele, altfel pierzi timp degeaba.
        if (allWordsMatch) {
          // Iterate over each word in searchTextWords
          searchTextWords.forEach((word) => {
            // Count the occurrences of the word in the title
            titleWordCount = (title.match(new RegExp(word, 'g')) || []).length;

            // Count the occurrences of the word in the description
            descriptionWordCount = (
              description.match(new RegExp(word, 'g')) || []
            ).length;

            // Increment matchCount by the sum of titleWordCount and descriptionWordCount
            matchCount += titleWordCount + descriptionWordCount;

            // Check for exact phrase match in the title
            if (title.includes(searchText)) {
              exactPhraseMatchTitle = true;
            } else if (description.includes(searchText)) {
              // Check for exact phrase match in the description
              exactPhraseMatchDescription = true;
            }
          });
        }

        // Return the task along with its computed match data
        return {
          id: task.id,
          exactPhraseMatchTitle,
          exactPhraseMatchDescription,
          titleWordCount,
          descriptionWordCount,
          subtasksCount,
          title,
          allWordsMatch,
        };
      })
      .filter((task) => task.allWordsMatch) // Only keep tasks with matches
      .sort((a, b) => {
        // Prioritize exact phrase match in title first
        if (b.exactPhraseMatchTitle && !a.exactPhraseMatchTitle) return 1;
        if (a.exactPhraseMatchTitle && !b.exactPhraseMatchTitle) return -1;

        // Then prioritize exact phrase match in description
        if (b.exactPhraseMatchDescription && !a.exactPhraseMatchDescription)
          return 1;
        if (a.exactPhraseMatchDescription && !b.exactPhraseMatchDescription)
          return -1;

        // Then by number of matches in title
        if (b.titleWordCount !== a.titleWordCount) {
          return b.titleWordCount - a.titleWordCount;
        }

        // Then by number of matches in description
        if (b.descriptionWordCount !== a.descriptionWordCount) {
          return b.descriptionWordCount - a.descriptionWordCount;
        }

        // Then by number of subtasks
        if (b.subtasksCount !== a.subtasksCount) {
          return b.subtasksCount - a.subtasksCount;
        }

        // Then by title length (smaller better)
        return a.title.length - b.title.length;
      });
  }, [tasksDataArray, searchTextWords, searchText]);

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    {
      console.log(e.key);
      if (e.key === 'Escape') {
        console.log('ESCAPE');
        if (searchText.length > 0) {
          setSearchText('');
          return;
        }
        dispatch(closeSearchMenu());
      }
    }
  };
  return (
    <div
      onClick={() => {
        setSearchText('');
        dispatch(closeSearchMenu());
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
        handleSearchKeyPress(e as React.KeyboardEvent<HTMLInputElement>)
      }
      className={`w-full h-full absolute z-[11] overflow-hidden bg-stone-800 bg-opacity-50
      ${isOpen ? 'block' : 'hidden'}`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`
          ${isOpen ? 'block' : 'hidden'}
          absolute left-1/2 transform -translate-x-1/2 top-2 sm:top-20
          w-11/12 sm:w-4/5 lg:w-2/3 2xl:w-1/2
          max-h-[80%]
          rounded-md shadow-md border-stone-300 border bg-stone-50 text-black
          flex flex-col `}
      >
        <input
          ref={searchRef}
          value={searchText}
          onChange={(e) => handleSearchChange(e.target.value)}
          className='rounded-t-md  p-2 pl-2 pr-2 bg-stone-50
          focus:outline-none '
          type='text'
          placeholder='Search in Task title and description...'
          autoFocus
        ></input>
        <div className='overflow-y-scroll no-scrollbar'>
          {searchText.length > 0 && (
            <div className='border-t border-stone-300 pb-1'>
              <div className='text-stone-500 font-bold text-xs p-2 pb-1'>
                Results{' '}
                <span className='font-normal'>
                  {filteredAndRankedTasks.length}
                </span>
              </div>
              {filteredAndRankedTasks.slice(0, 15).map((task, index) => {
                return (
                  <div className='pl-1 pr-1'>
                    <Task
                      key={index}
                      taskId={task.id}
                      highlightWords={searchTextWords}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {searchText.length === 0 && (
            <div className='border-t border-stone-300'>
              <div className='text-stone-500 font-bold p-2 pb-1 text-xs'>
                Recently created <span className='font-normal'>5</span>
              </div>
              {tasksDataArray
                .reverse()
                .slice(0, 5)
                .map((task, index) => {
                  return <Task key={index} taskId={task.id} />;
                })}
            </div>
          )}
        </div>
        <div className=' border-t rounded-b-md border-stone-300 bg-stone-200 text-xs text-stone-500 p-2 pl-2 pr-2 hover:text-stone-700'>
          <span className=''>
            <span className='font-semibold text-stone-700'>Tip:</span> Navigate
            results using arrows:
            <ShortcutLabel genericKey={'ArrowUp'} /> and
            <ShortcutLabel genericKey={'ArrowDown'} />
          </span>
          ,
          <span className='ml-1'>
            Open:
            <ShortcutLabel genericKey={'Enter'} />
          </span>
          ,
          <span className='ml-1'>
            Empty / Close search:
            <ShortcutLabel genericKey={'Escape'} />.{' '}
            <span
              data-tooltip-id='search-tooltip'
              data-tooltip-content='If you want more precise search you can use words "inside commas".'
              className='cursor-pointer border-stone-400 border-b border-dashed'
            >
              More tips
            </span>
          </span>
        </div>
      </div>
      <Tooltip id='search-tooltip' />
    </div>
  );
};

export default SearchMenu;
