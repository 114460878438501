import { createSlice } from '@reduxjs/toolkit';

const transportationSlice = createSlice({
  name: 'transportation',
  initialState: {
    taskId: null,
    transportationType: 1, //1: Adding a new parent, keeping the current ones. 2: Moving to a new parent while removing from 'name_of_parent'.
    parentReplacedId: null,
  },
  reducers: {
    startTransportation: (state, action) => {
      const {
        taskId,
        transportationType = 1,
        parentReplacedId = null,
      } = action.payload;
      state.taskId = taskId;
      state.transportationType = transportationType;
      state.parentReplacedId = parentReplacedId;
    },
    stopTransportation: (state) => {
      state.taskId = null;
      state.transportationType = 1;
      state.parentReplacedId = null;
    },
  },
});

export const { startTransportation, stopTransportation } =
  transportationSlice.actions;
export default transportationSlice.reducer;
