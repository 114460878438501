import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faEllipsis,
  faCodePullRequest,
  faScissors,
  faCodeBranch,
  faAlignLeft,
} from '@fortawesome/free-solid-svg-icons';

import { useState, useMemo, useEffect } from 'react';
import { PAGES } from '../../utils/constants';
import { combineTasks, replaceParentsTasks } from '../../api/taskApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  finishLoading,
  setError,
  startLoading,
} from '../../state/loadingSlice';
import {
  combineTasksInTasks,
  uncombineTasksInTasks,
} from '../../state/tasksSlice';
import { faClock, faFolder } from '@fortawesome/free-regular-svg-icons';
import { openModal } from '../../state/modalSlice';
import { getPrettyDate } from '../../utils/dateFormatter';
import { openDropDownMenu } from '../../state/dropDownMenuSlice';
import {
  startTransportation,
  stopTransportation,
} from '../../state/transportationSlice';
import { closeSearchMenu } from '../../state/searchMenuSlice';
import highlightText from '../../utils/highlightText';
import shortenText from '../../utils/shortenText';
import TaskCheckbox from './TaskCheckbox';
import { RootState } from '../../state/store';
import { DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';

function Task({
  provided,
  snapshot,
  taskId,
  space,
  taskNestLevel = 0,
  taskNestParent = null,
  comesFromParent = null,
  highlightWords = [],
  childrenClickCallback,
}: {
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  taskId: string;
  space?: string;
  taskNestLevel?: number;
  taskNestParent?: string | null;
  comesFromParent?: string | null;
  highlightWords?: string[];
  childrenClickCallback?: () => void;
}) {
  const { loading } = useSelector((state: RootState) => state.loading);
  const [isDropDownMenuActive, setDropDownMenuActive] = useState(false);
  // console.log('Task curent: ' + JSON.stringify(task));
  const dispatch = useDispatch();
  const tasksData = useSelector((state: RootState) => state.tasks);
  const transportationData = useSelector(
    (state: RootState) => state.transportation
  );
  const { shortTitles } = useSelector((state: RootState) => state.settings);
  const {
    id,
    title,
    description,
    status,
    scheduled_date,
    scheduled_type,
    children = [],
    parents,
  } = tasksData[taskId];
  // const isParentVisible = space === PAGES.MODAL ? false : true;
  const isTimeVisible =
    (space === PAGES.DEFAULT ||
      space === PAGES.DAYS ||
      space === PAGES.WEEKS ||
      space === PAGES.MONTHS ||
      space === PAGES.YEARS) &&
    taskNestLevel === 0
      ? false
      : true;
  const scheduledDateFormatted = getPrettyDate(scheduled_date, scheduled_type);
  const childrenCompletedCount =
    children.length > 0
      ? children.reduce((count, child) => {
          return tasksData[child].status ? count + 1 : count;
        }, 0)
      : 0;
  const modalData = useSelector((state: RootState) => state.modal);
  const modalDataActiveTask = modalData.content?.taskId || null;
  const comesFromSpecificParent = comesFromParent
    ? comesFromParent
    : taskNestParent
    ? taskNestParent
    : modalDataActiveTask
    ? modalDataActiveTask
    : null;
  const dropDownMenuSelectedTaskId = useSelector(
    (state: RootState) => state.dropDownMenu
  );
  useEffect(() => {
    if (dropDownMenuSelectedTaskId.taskId !== taskId) {
      setDropDownMenuActive(false);
    }
  }, [dropDownMenuSelectedTaskId]);

  const [isSubtaskVisible, setIsSubtaskVisible] = useState(false);
  const isSubtaskClickable = space === PAGES.TASKPERSPECTIVE ? false : true;

  const titleShortened: { isLong: boolean; tooltip: {}; value: string } =
    useMemo(() => {
      return shortTitles
        ? shortenText(title, 'task-tooltip', 30)
        : { isLong: false, tooltip: {}, value: title };
    }, [shortTitles, title]);

  const handleSubtaskClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isSubtaskClickable) {
      setIsSubtaskVisible(!isSubtaskVisible);
    } else {
      if (childrenClickCallback) {
        childrenClickCallback();
      }
    }
  };

  const handleTaskClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) {
      return;
    }
    if (transportationData.taskId) {
      if (transportationData.taskId === id) {
        return;
      }

      if (transportationData.transportationType === 1) {
        dispatch(startLoading('Updating tasks...'));
        try {
          await combineTasks({
            id: transportationData.taskId,
            parent_id: taskId,
          });
          dispatch(
            combineTasksInTasks({
              parentId: taskId,
              childId: transportationData.taskId,
            })
          );
          dispatch(stopTransportation());
        } catch (error) {
          console.error(error);
          dispatch(
            setError({
              message: "Can't update tasks, please try again.",
              error: error,
            })
          );
        } finally {
          dispatch(finishLoading());
        }
      } else if (transportationData.transportationType === 2) {
        dispatch(startLoading('Moving task...'));
        try {
          await replaceParentsTasks({
            id: transportationData.taskId,
            parent_id: transportationData.parentReplacedId,
            parent_replacement_id: taskId,
          });
          dispatch(
            uncombineTasksInTasks({
              parentId: transportationData.parentReplacedId,
              childId: transportationData.taskId,
            })
          );
          dispatch(
            combineTasksInTasks({
              parentId: taskId,
              childId: transportationData.taskId,
            })
          );
          dispatch(stopTransportation());
        } catch (error) {
          dispatch(
            setError({
              message: "Can't move task, please try again.",
              error: error,
            })
          );
        } finally {
          dispatch(finishLoading());
        }
      }
    } else {
      dispatch(openModal({ taskId: id }));
      dispatch(closeSearchMenu());
    }
  };

  const handleMoreClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('More Menu for: ' + id);
    console.log(e.clientX);
    console.log(e.clientY);
    setDropDownMenuActive(true);
    dispatch(openDropDownMenu({ taskId: id, x: e.clientX, y: e.clientY }));
  };

  const handleTaskTransportationClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(startTransportation({ taskId: id }));
  };

  const handleTaskTransportationMoveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log('NESTED: ' + taskNestParent);
    // console.log('MODAL: ' + modalDataActiveTask);
    // console.log('ALESUL: ' + comesFromSpecificParent);
    dispatch(
      startTransportation({
        taskId: id,
        transportationType: 2,
        parentReplacedId: comesFromSpecificParent,
      })
    );
  };

  return (
    <div
      onClick={handleTaskClick}
      ref={provided ? provided.innerRef : null}
      {...(provided ? provided.draggableProps : {})}
      {...(provided ? provided.dragHandleProps : {})}
      className={`block overflow-wrap-anywhere select-none
        ${snapshot ? 'cursor-grab' : 'cursor-pointer'}
        `}
    >
      <div
        className={`group/task relative overflow-hidden  flex items-baseline  p-1 rounded-md transition-colors border border-transparent
          hover:bg-stone-200 
          ${snapshot && snapshot.isDragging ? 'bg-stone-200' : ''} 
          ${status == 1 ? 'text-stone-400' : ''}
          ${status == 2 ? 'text-red-500' : ''}
          ${snapshot && snapshot.combineTargetFor ? 'bg-emerald-200' : ''} 
          ${snapshot && snapshot.combineWith ? 'bg-emerald-200' : ''} 
          ${isDropDownMenuActive ? 'bg-stone-200' : ''}
          ${transportationData.taskId ? 'cursor-copy' : ''}
          ${transportationData.taskId === id ? 'cursor-not-allowed' : ''}
          ${
            transportationData.taskId === id &&
            transportationData.transportationType === 1
              ? 'bg-emerald-300 hover:bg-emerald-300'
              : ''
          }
          ${
            transportationData.taskId === id &&
            transportationData.transportationType === 2
              ? 'bg-orange-200 hover:bg-orange-200'
              : ''
          }
      `}
      >
        <div className='w-4.5 h-4.5 mr-1'>
          <TaskCheckbox taskId={taskId} />
        </div>
        <div>
          <div
            className={`
              sm:text-sm
              ${status == 2 ? 'text-red-500 line-through' : ''}`}
          >
            {highlightWords.length === 0 ? (
              <span {...titleShortened.tooltip}>{titleShortened.value}</span>
            ) : (
              highlightText(title, highlightWords)
            )}
          </div>
          {highlightWords.length > 0 && description && (
            <div className='text-stone-400 text-xs'>
              <FontAwesomeIcon icon={faAlignLeft} className='mr-1' />
              {highlightText(description, highlightWords)}
            </div>
          )}
          <div className='italic text-stone-400 text-xs'>
            {parents &&
              parents.map((parent, index) => {
                if (comesFromSpecificParent === parent) {
                  return;
                }
                const parentTitle = shortenText(
                  tasksData[parent].title,
                  'task-tooltip'
                );
                return (
                  <div
                    key={index}
                    // className={index % 2 !== 0 ? 'text-stone-300' : ''}
                  >
                    <FontAwesomeIcon
                      icon={faCodeBranch}
                      flip='vertical'
                      className='text-stone-300'
                    />{' '}
                    <span
                      {...parentTitle.tooltip}
                      data-tooltip-delay-show={500}
                    >
                      {parentTitle.value}
                    </span>
                  </div>
                );
              })}
          </div>
          {isTimeVisible && scheduledDateFormatted && (
            <div className='italic text-stone-400 text-xs'>
              <FontAwesomeIcon icon={faClock} className='mr-1 text-stone-300' />
              {scheduledDateFormatted}
            </div>
          )}
          {children && children.length > 0 && (
            <div
              className='italic text-stone-400 text-xs cursor-pointer'
              onClick={handleSubtaskClick}
            >
              {childrenCompletedCount}/{children.length} subtasks{' '}
              {isSubtaskClickable && (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className={`transition-transform duration-300 ${
                    isSubtaskVisible ? 'rotate-180' : ''
                  }`}
                />
              )}
            </div>
          )}
        </div>

        <div className='hidden sm:flex absolute opacity-1 right-1  flex-row'>
          {!transportationData.taskId && (
            <>
              <button
                data-tooltip-id='task-tooltip'
                data-tooltip-content='Move from current parent to...'
                onClick={handleTaskTransportationMoveClick}
                className={`opacity-0 group-hover/task:opacity-100 h-5 w-5 mr-1 flex items-center justify-center bg-stone-50 rounded-full 
                transition duration-300 hover:bg-stone-400 
                ${comesFromSpecificParent ? 'block' : 'hidden'}
          `}
              >
                <FontAwesomeIcon
                  icon={faScissors}
                  className='text-stone-800 h-3 w-3'
                />
              </button>

              <button
                data-tooltip-id='task-tooltip'
                data-tooltip-content='Add to new parent & keep the current ones...'
                onClick={handleTaskTransportationClick}
                className={`opacity-0 group-hover/task:opacity-100 h-5 w-5 mr-1 flex items-center justify-center bg-stone-50 rounded-full 
          transition duration-300 hover:bg-stone-400 `}
              >
                <FontAwesomeIcon
                  icon={faCodePullRequest}
                  className='text-stone-800 h-3 w-3'
                />
              </button>

              <button
                data-tooltip-id='task-tooltip'
                data-tooltip-content='More...'
                className={` opacity-0 group-hover/task:opacity-100 h-5 w-5 flex items-center justify-center bg-stone-50 rounded-full 
          transition duration-300 hover:bg-stone-400 
          ${isDropDownMenuActive ? '!bg-stone-400 opacity-100' : ''}
          `}
                onClick={handleMoreClick}
              >
                <FontAwesomeIcon icon={faEllipsis} className='text-stone-800' />
              </button>
            </>
          )}
        </div>
      </div>
      <div className='ml-5'>
        {isSubtaskVisible &&
          children &&
          children.map((child, index) => {
            // return <div>{tasksData[child].title}</div>;
            return (
              <Task
                key={tasksData[child].id}
                taskId={tasksData[child].id}
                space={space}
                taskNestLevel={taskNestLevel + 1}
                taskNestParent={taskId}
              />
            );
          })}
      </div>
    </div>
  );
}

export default Task;
