import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import './App.css';
import { useEffect } from 'react';
import { finishLoading, setError, startLoading } from './state/loadingSlice';
import { fillColumns } from './state/columnsSlice';
import { decryptTasks, fillTasks } from './state/tasksSlice';
import StatusIndicator from './components/StatusIndicator';
import { Tooltip } from 'react-tooltip';
import DropDownMenu from './components/DropDownMenu';
import Transportation from './components/Helpers/Transportation';
import Modal from './components/Task/Modal';
import SearchMenu from './components/Overlays/SearchMenu';
import { openSearchMenu } from './state/searchMenuSlice';
import useKeyboardShortcuts from './utils/useKeyboardShortcuts';
import ShortcutLabel from './components/Helpers/ShortcutLabel';
import SettingsMenu from './components/SettingsMenu';
import { NAVBAR_LINKS } from './utils/constants';
import { setKeys } from './state/keysSlice';
import Unlock from './components/Auth/Unlock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getTasks, keyParams } from './api/taskApi';
import { RootState } from './state/store';
import { setIsMobile } from './state/mobileSlice';

function App() {
  const navigate = useNavigate();
  const { masterKey, encryptedItemsKeys, encryptedItemsKeyId } = useSelector(
    (state: RootState) => state.keys
  );
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const keyParamsQuery = useQuery(['keyParams'], keyParams, {
    refetchOnWindowFocus: false, // Do not refetch when window is focused
    refetchOnReconnect: false, // Do not refetch on reconnection
    refetchOnMount: true, // Do not refetch on component remount
    retry: false, // Do not retry on error
    enabled: !encryptedItemsKeyId, //If we don't have it, then request it.
  });

  useEffect(() => {
    if (keyParamsQuery.isSuccess && !encryptedItemsKeyId) {
      const keyParams = keyParamsQuery.data.data;
      console.log('ia: ' + JSON.stringify(keyParams));
      dispatch(
        setKeys({
          identifier: keyParams.email,
          pw_nonce: keyParams.pw_nonce,
          encryptedItemsKeys: keyParams.encrypted_items_keys,
          encryptedItemsKeyId: keyParams.encrypted_items_key_id,
        })
      );
    }
  }, [keyParamsQuery.isSuccess]);

  useEffect(() => {
    if (keyParamsQuery.isError) {
      //ori nu gaseste server, ori nu e logat. pt prima ii dau pagina de eroare, pt a 2a il duc pe login.
      // TODO: Trateaza pe ambele la fel. Ia in considerare sa ii dau logout daca-l trimit pe login ca oricum nu mai are access.
      queryClient.removeQueries(['keyParams']);
      return navigate('/login');
    }
  }, [keyParamsQuery.isError]);

  const tasksQuery = useQuery(['tasks'], getTasks, {
    refetchOnWindowFocus: false, // Do not refetch when window is focused
    //da-i o variabila newDay ce e true daca e zi noua.
    refetchOnReconnect: false, // Do not refetch on reconnection
    refetchOnMount: true, // Do not refetch on component remount
    enabled: !!encryptedItemsKeyId, // If we have it, then request tasks, but only on mount so it's both conditions. And on mount we don't have the tasks so it's exactly what we need.
  });

  useEffect(() => {
    if (tasksQuery.isLoading) {
      dispatch(startLoading('Syncing tasks...'));
    } else {
      dispatch(finishLoading());
    }
    if (tasksQuery.isError) {
      dispatch(setError({ message: 'Syncing tasks failed...' }));
    } else if (tasksQuery.data) {
      const data = tasksQuery.data.data;
      console.log('API DATA: ' + JSON.stringify(data));
      dispatch(fillColumns({ columns: data.columns }));
      dispatch(fillTasks({ tasks: data.tasks }));
      dispatch(finishLoading());
    }
  }, [tasksQuery.isSuccess]);

  useEffect(() => {
    if (masterKey) {
      dispatch(decryptTasks({ masterKey, encryptedItemsKeys }));
    }
  }, [masterKey, tasksQuery.isSuccess]);

  useKeyboardShortcuts([
    {
      keyCombo: 'k',
      action: () => {
        dispatch(openSearchMenu());
      },
    },
  ]);

  const handleSearchNavBarClick = () => {
    dispatch(openSearchMenu());
  };

  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(window.innerWidth <= 640));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return keyParamsQuery.isLoading || !masterKey ? (
    <div className='bg-stone-300 h-full overflow-auto w-full justify-center flex'>
      {keyParamsQuery.isLoading ? (
        <div className='flex flex-col items-center justify-center p-5'>
          <div className='mb-1'>Perspectask</div>
          <FontAwesomeIcon icon={faSpinner} spin className='text-stone-500' />
        </div>
      ) : !masterKey && !keyParamsQuery.isError ? (
        <Unlock />
      ) : (
        ''
      )}
    </div>
  ) : (
    <div className='h-full flex flex-col'>
      <div className='relative flex flex-row h-full overflow-hidden'>
        <div className='hidden sm:flex flex-col justify-between bg-stone-200'>
          <nav className='flex-initial p-1 pt-2 flex flex-col'>
            {NAVBAR_LINKS.map((link) => (
              <NavLink
                key={link.title}
                to={link.path}
                className={({ isActive }) =>
                  `transition duration-300 text-stone-500 rounded-md p-0.5 pl-2 pr-2 flex items-center justify-between  ${
                    isActive
                      ? 'bg-stone-400/40 text-stone-900'
                      : 'hover:bg-stone-400/30'
                  }
                  ${link.mt ? link.mt : ''}`
                }
              >
                {link.title}
              </NavLink>
            ))}
            <button
              onClick={handleSearchNavBarClick}
              className='mt-2 transition duration-300 text-stone-500 rounded-md p-0.5 pl-2 pr-2 hover:bg-stone-400/30 text-left flex items-center justify-between'
            >
              <FontAwesomeIcon icon={faSearch} className='mr-1' />
              <span>Search</span>
              <ShortcutLabel keyShortcut={'K'} />
            </button>
            {import.meta.env.MODE === 'development' && (
              <p className='text-red-500 border bg-red-300 border-red-600 rounded-md text-center text-3xl mt-5'>
                DEV!
              </p>
            )}
          </nav>
          <div className='flex flex-row items-center justify-end  p-1 pb-1.5 relative'>
            <SettingsMenu />
          </div>
        </div>
        <div className='bg-white flex-1 overflow-y-scroll no-scrollbar'>
          <Outlet />
        </div>

        <StatusIndicator />
        <Transportation />
      </div>
      <Modal />
      <DropDownMenu />
      <SearchMenu />
      <Tooltip className='z-[14] max-w-sm' id='task-tooltip' />
      {/* Mobile-only navbar */}
      <nav className='sm:hidden h-12 divide-x divide-zinc-500  w-full flex flex-row border-t border-zinc-500'>
        {NAVBAR_LINKS.filter((link) => link.isMobile).map((link) => (
          <NavLink
            key={link.title}
            to={link.path}
            className={({ isActive }) =>
              `
            flex-1 flex items-center justify-center content-center text-center grow 
            transition duration-300   ${
              isActive ? 'bg-stone-500/40 text-stone-900' : 'text-stone-500'
            }`
            }
          >
            {link.title[0]}
          </NavLink>
        ))}

        <NavLink
          to='/life'
          className={({ isActive }) =>
            `
            flex-1 flex items-center justify-center text-center 
            transition duration-300   ${
              isActive
                ? 'bg-stone-500/40 text-stone-900'
                : ' bg-stone-300 text-stone-500'
            }`
          }
        >
          L
        </NavLink>

        <button
          onClick={handleSearchNavBarClick}
          className={`
            flex-1 flex items-center justify-center bg-stone-300
            transition duration-300 text-stone-500
            `}
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>

        <button className='flex-1 flex items-center justify-center bg-stone-300'>
          S
        </button>
      </nav>
    </div>
  );
}

export default App;
