import {
  faLock,
  faRightFromBracket,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { decryptText, generatePasswordKeys } from '../../utils/encryption';
import { setKeys } from '../../state/keysSlice';
import { RootState } from '../../state/store';
import logout from '../../utils/logout';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

const Unlock = () => {
  const [password, setPassword] = useState('');
  const { identifier, pw_nonce, encryptedItemsKeys, encryptedItemsKeyId } =
    useSelector((state: RootState) => state.keys);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null); // Updated to allow string type
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!password.length) {
      setError('Enter password');
      return;
    }
    setError(null);
    setLoading(true);
    const rootKeys = await generatePasswordKeys(identifier, password, pw_nonce);
    const masterKey = rootKeys.masterKey;
    //Check if this key can decrypt itemsKey. If not, this is not the correct key.

    try {
      decryptText(encryptedItemsKeys[encryptedItemsKeyId], masterKey);
      dispatch(setKeys({ masterKey }));
    } catch (error) {
      setError('Incorrect password');
    } finally {
      setLoading(false);
    }
  };

  const handleLogOutClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    logout(dispatch, navigate, queryClient);
  };
  return (
    <div className=' h-fit min-h-full w-full flex items-center justify-center py-5'>
      <div
        className='flex flex-col drop-shadow-md border border-stone-400/30 sm:w-64 w-4/5 rounded-md bg-stone-200 
    text-stone-700 px-5 py-6 pb-3 items-center  h-fit'
      >
        <FontAwesomeIcon
          icon={faLock}
          className='text-stone-600 bg-stone-100 rounded-full p-9 w-12 h-12 mb-5'
        />
        <span className='text-center text-sm font-semibold'>
          Authentification is required to decrypt the tasks.
        </span>
        <form onSubmit={handleSubmit}>
          <input
            value={password}
            disabled={loading}
            onChange={(e) => setPassword(e.target.value)}
            className='w-full p-1 px-2 rounded-md shadow-md mt-3 outline-none'
            placeholder='Account Password'
            type='password'
          ></input>
          <button
            disabled={loading}
            className='w-full font-semibold items-center justify-center flex p-1 px-2 rounded-md shadow-md mt-2 
          bg-blue-700 text-stone-200 disabled:bg-stone-400'
          >
            {loading ? (
              <>
                <FontAwesomeIcon icon={faSpinner} spin className='mr-1' />{' '}
                Unlocking...
              </>
            ) : (
              'Unlock'
            )}
          </button>
          {error && (
            <div
              className={`text-red-500 text-center font-semibold  border-red-500 bg-red-300 rounded-md  text-sm
            transition-all duration-500 overflow-hidden max-h-40 py-1 mt-2 border`}
            >
              {error}
            </div>
          )}
        </form>
        <span className='text-xs text-stone-400 italic mt-5 text-center'>
          <span className='font-semibold'>Tip:</span> You can setup a shorter
          passcode in settings or use a secure password manager.
        </span>
      </div>
      <div className='absolute bottom-0 right-0 p-1 text-stone-400/70'>
        <button
          onClick={handleLogOutClick}
          data-tooltip-id='unlock-tooltip'
          data-tooltip-content='Log out'
          data-tooltip-place='top-start'
        >
          <FontAwesomeIcon icon={faRightFromBracket} />
        </button>
      </div>
      <Tooltip id='unlock-tooltip' />
    </div>
  );
};

export default Unlock;
