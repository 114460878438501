import { Droppable } from '@hello-pangea/dnd';
import TaskDraggable from '../Task/TaskDraggable';
import AddTask from '../Task/AddTask';
import { PAGES } from '../../utils/constants';
import { idToScheduled } from '../../utils/idToScheduled';

function ColumnDroppable({ columnId, tasksIds, space }) {
  const addTaskData = {};
  if (space === PAGES.MODAL) {
    addTaskData['parent_id'] = columnId;
  } else {
    const convertedDate = idToScheduled(columnId);
    addTaskData['scheduled_date'] = convertedDate.scheduled_date;
    addTaskData['scheduled_type'] = convertedDate.scheduled_type;
    addTaskData['column_id'] = columnId;
  }

  return (
    <Droppable droppableId={columnId} key={columnId} isCombineEnabled>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className='h-full flex flex-col pb-14 overflow-y-scroll droppableZone'
        >
          {tasksIds.map((taskId, index) => {
            return (
              <TaskDraggable
                key={taskId}
                taskId={taskId}
                index={index}
                space={space}
              />
            );
          })}
          {provided.placeholder}
          <AddTask addTaskData={addTaskData} />
        </div>
      )}
    </Droppable>
  );
}

export default ColumnDroppable;
