export const exportJSONToTxt = (jsonData) => {
  const jsonString = JSON.stringify(jsonData, null, 2); // Format JSON with indentation
  const blob = new Blob([jsonString], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = 'data.txt';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
