import { createSlice } from '@reduxjs/toolkit';

interface DisplayDateState {
  displayDate: number; // Define the type for displayDate
}

const initialState: DisplayDateState = {
  displayDate: new Date().setHours(0, 0, 0, 0),
};

const displayDateSlice = createSlice({
  name: 'displayDate',
  initialState,
  reducers: {
    setDisplayDate: (state, action) => {
      state.displayDate = action.payload;
    },
  },
});

export const { setDisplayDate } = displayDateSlice.actions;
export default displayDateSlice.reducer;
