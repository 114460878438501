export const PAGES = {
  DEFAULT: 'DEFAULT',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
  MODAL: 'MODAL',
  ALLTASKS: 'ALLTASKS',
  NOT_SCHEDULED: 'NOT_SCHEDULED',
  NOT_ORGANIZED: 'NOT_ORGANIZED',
  TASKPERSPECTIVE: 'TASKPERSPECTIVE',
};

export const NAVBAR_LINKS = [
  {
    title: 'Perspectask',
    path: '/',
  },
  {
    title: 'Days',
    path: '/days',
    isMobile: true,
  },
  {
    title: 'Weeks',
    path: '/weeks',
    isMobile: true,
  },
  {
    title: 'Months',
    path: '/months',
    isMobile: true,
  },
  {
    title: 'Years',
    path: '/years',
    isMobile: true,
  },
  {
    title: 'All Tasks',
    path: '/all-tasks',
    mt: 'mt-2',
  },
  {
    title: 'Reflection',
    path: '/reflection',
  },
];
