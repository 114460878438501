import { finishLoading, setError, startLoading } from '../state/loadingSlice';
import { logoutUser } from '../api/taskApi';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';

const logout = async (
  dispatch: Dispatch,
  navigate: NavigateFunction,
  queryClient: QueryClient
) => {
  dispatch(startLoading('Logging out...'));
  try {
    const response = await logoutUser();
    // Handle successful login, e.g., redirect or store token
    console.log('Logout successful:', response);
    queryClient.cancelQueries(['tasks']); // Cancel the query to prevent refetch
    queryClient.removeQueries(['tasks']); // Remove the cached data without refetching

    if (response.success) {
      navigate('/login');
    }
  } catch (error) {
    console.log('prinsa eroare');
    console.log({ error });
    dispatch(
      setError({
        message: 'Logout failed.',
        error: error,
      })
    );
  } finally {
    dispatch(finishLoading());
  }
};

export default logout;
