const highlightText = (text, highlightWords) => {
  if (highlightWords.length === 0) {
    return text;
  } else {
    const regex = new RegExp(`(${highlightWords.join('|')})`, 'gi');

    // Split the text by the regex and wrap the matching parts with a span
    const parts = text.split(regex);
    console.log({ parts });
    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <span key={index} className='bg-yellow-200 font-bold'>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  }
};

export default highlightText;
