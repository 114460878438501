import axios from 'axios';
const baseUrl = import.meta.env.VITE_API_BASE_URL;
axios.defaults.withCredentials = true;

export const loginUser = async (data) => {
  console.log({ data });
  const response = await axios.post(`${baseUrl}/auth/login`, data);
  return response.data;
};

export const loginParams = async (data) => {
  console.log({ data });
  const response = await axios.post(`${baseUrl}/auth/login-params`, data);
  return response.data;
};

export const keyParams = async () => {
  const response = await axios.get(`${baseUrl}/auth/key-params`);
  return response;
};

export const registerUser = async (data) => {
  console.log({ data });
  const response = await axios.post(`${baseUrl}/auth/register`, data);
  return response.data;
};

export const logoutUser = async (data) => {
  console.log({ data });
  const response = await axios.post(`${baseUrl}/auth/logout`, data);
  return response.data;
};

//Tasks

export const getTasks = async () => {
  const response = await axios.get(`${baseUrl}/tasks`);
  return response;
};

export const postTask = async ({ data }) => {
  console.log({ data });
  const response = await axios.post(`${baseUrl}/tasks`, data);
  return response.data;
};

export const updateTask = async ({ id, data }) => {
  const response = await axios.patch(`${baseUrl}/tasks/${id}`, data);
  return response.data;
};

export const combineTasks = async ({ id, parent_id }) => {
  const response = await axios.post(`${baseUrl}/tasks/${id}/combine`, {
    parent_id,
  });
  return response.data;
};

export const uncombineTasks = async ({ id, parent_id }) => {
  const response = await axios.post(`${baseUrl}/tasks/${id}/combine`, {
    parent_id: parent_id,
    action: 'remove',
  });
  return response.data;
};

export const replaceParentsTasks = async ({
  id,
  parent_id,
  parent_replacement_id,
}) => {
  const response = await axios.post(`${baseUrl}/tasks/${id}/combine`, {
    parent_id: parent_id,
    parent_replacement_id: parent_replacement_id,
  });
  return response.data;
};

export const deleteTask = async (id) => {
  const response = await axios.delete(`${baseUrl}/tasks/${id}`);
  return response.data;
};
