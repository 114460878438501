import { createSlice } from '@reduxjs/toolkit';

const mobileSlice = createSlice({
  name: 'mobile',
  initialState: {
    isMobile: window.innerWidth <= 640,
  },
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

export const { setIsMobile } = mobileSlice.actions;
export default mobileSlice.reducer;
