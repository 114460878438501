import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns/format';
import { RootState } from '../state/store';

const StatusIndicator = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { loading, message, error, lastMessages } = useSelector(
    (state: RootState) => state.loading
  );
  const modalData = useSelector((state: RootState) => state.modal);
  const { isMobile } = useSelector((state: RootState) => state.mobile);

  return (
    <div
      className={`flex-col absolute  left-0 z-20 
    ${modalData.isOpen && isMobile ? 'top-0' : 'bottom-0'}
    `}
    >
      <div
        className={`flex flex-col items-start font-bold left-1 p-2 text-center text-sm bg-gray-200 border border-gray-300 rounded-md shadow-md  transition 
          ${
            error || showTooltip || loading
              ? 'opacity-100 duration-0'
              : 'opacity-0 duration-1000 pointer-events-none hidden'
          }
          ${error ? 'bg-red-200 text-red-700  border-red-600' : ''}
        `}
      >
        {showTooltip && lastMessages && lastMessages.length > 0 && (
          <table className={``}>
            <tbody>
              {lastMessages.map((m, index) => {
                //convert the date to HH:MM with date-fns
                const hourTime = format(m.date, 'HH:mm:ss');
                return (
                  <tr
                    key={index}
                    className={`${
                      m.success ? 'text-stone-600' : 'text-red-600'
                    }`}
                  >
                    <td className='font-medium'>{hourTime}:</td>
                    <td className='font-light text-left'>{m.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {error ? 'Error: ' + error : message ? message : 'All changes saved'}
      </div>
      {/* )} */}
      <div className='h-8 w-8'>
        <div
          className=' p-1 border flex:none w-8 border-transparent rounded-md opacity-100'
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {!loading && !error && (
            <FontAwesomeIcon
              className='text-white bg-lime-700 rounded-full w-3 h-3 p-1'
              icon={faCheck}
            />
          )}
          {loading && (
            <FontAwesomeIcon
              className='w-5 h-5 text-black  mix-blend-difference'
              icon={faSpinner}
              spinPulse
            />
          )}
          {error && (
            <FontAwesomeIcon
              className='text-white bg-red-500 rounded-full w-3 h-3 p-1'
              icon={faXmark}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusIndicator;
