import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { getPrettyDate } from '../../utils/dateFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { openDatePicker } from '../../state/dropDownMenuSlice';

export default function TaskDate({ taskId }: { taskId: string }) {
  const tasksData = useSelector((state: RootState) => state.tasks);
  const { scheduled_date, scheduled_type } = tasksData[taskId] || {};
  const scheduledDateFormatted = getPrettyDate(scheduled_date, scheduled_type);

  const scheduledDateRef = useRef<HTMLButtonElement>(null); // Specify the type of the ref
  const dispatch = useDispatch();

  const handleDateClick = () => {
    if (scheduledDateRef.current) {
      // Check if current is not null
      const buttonPosition = scheduledDateRef.current.getBoundingClientRect();
      dispatch(
        openDatePicker({
          taskId: taskId,
          x: buttonPosition.left,
          y: buttonPosition.bottom,
        })
      );
    }
  };

  return (
    scheduledDateFormatted && (
      <>
        <FontAwesomeIcon
          icon={faClock}
          className='mr-1 text-stone-300  h-4 w-4'
        />
        <button
          ref={scheduledDateRef}
          onClick={handleDateClick}
          className='
                 p-1 pl-2 pr-2 rounded-md
                transition duration-300 hover:bg-stone-300 hover:text-stone-700'
        >
          {scheduledDateFormatted}
        </button>
      </>
    )
  );
}
