import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import store from './state/store.js';
import App from './App.js';
import NotFoundPage from './pages/NotFoundPage.jsx';
import DaysPage from './pages/DaysPage.js';
import TaskPage from './pages/TaskPage.jsx';
import AllTasksPage from './pages/AllTasksPage.js';
import { PAGES } from './utils/constants.js';
import './index.css';

import TaskPerspectivePage from './pages/TaskPerspectivePage.js';
import AuthPage from './pages/AuthPage.jsx';
import AccountPage from './pages/AccountPage.jsx';
import ReflectionPage from './pages/ReflectionPage.jsx';
import * as Sentry from '@sentry/react';

// Stoppped temoporary pana cand vad cum sa nu trimit date senzibile.
Sentry.init({
  dsn: 'https://f72fd2d4310401906bb11222a68a9bc7@o4508064298762240.ingest.de.sentry.io/4508064302039120',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.perspectask\.com/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/',
        element: <DaysPage spaceType={PAGES.DEFAULT} />,
      },
      {
        path: '/days',
        element: <DaysPage spaceType={PAGES.DAYS} />,
      },
      {
        path: '/weeks',
        element: <DaysPage spaceType={PAGES.WEEKS} />,
      },
      {
        path: '/months',
        element: <DaysPage spaceType={PAGES.MONTHS} />,
      },
      {
        path: '/years',
        element: <DaysPage spaceType={PAGES.YEARS} />,
      },
      {
        path: '/all-tasks',
        element: <AllTasksPage />,
      },
      {
        path: '/reflection',
        element: <ReflectionPage />,
      },
      {
        path: '/task-perspective/:id',
        element: <TaskPerspectivePage />,
      },
      {
        path: '/account',
        element: <AccountPage />,
      },
    ],
  },
  {
    path: '/task/:id',
    element: <TaskPage />,
  },
  {
    path: '/login',
    element: <AuthPage isSignUp={false} />,
  },
  {
    path: '/register',
    element: <AuthPage isSignUp={true} />,
  },
]);

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
