import { useDispatch, useSelector } from 'react-redux';
import { finishLoading, setError, startLoading } from '../state/loadingSlice';
import { updateTask } from '../api/taskApi';
import { updateTaskInTasks } from '../state/tasksSlice';
import { closeDropDownMenu } from '../state/dropDownMenuSlice';
import { addTaskToColumn, removeTaskFromColumn } from '../state/columnsSlice';
import {
  getFormattedDate,
  getFormattedDay,
  getFormattedWeek,
} from '../utils/dateFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { idToScheduled } from '../utils/idToScheduled';
import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  format,
  getWeek,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isSameYear,
  isThisWeek,
  isToday,
  isTomorrow,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { RootState } from '../state/store';

const DatePicker = () => {
  const datePickerData = useSelector((state) => state.dropDownMenu);
  const { taskId, datePicker, datePickerX, datePickerY, datePickerMenuWidth } =
    datePickerData;
  const taskData = useSelector((state) => state.tasks[taskId]);
  const { isMobile } = useSelector((state: RootState) => state.mobile);
  const { columnId, scheduled_date, scheduled_type } = taskData || {};
  const datePickerRef = useRef(null);
  const dispatch = useDispatch();
  // console.log(datePicker);
  // console.log({ scheduled_date });

  const [positionDatePicker, setPositionDatePicker] = useState(null);

  useEffect(() => {
    if (!datePicker) {
      setPositionDatePicker(null);
      return;
    }
    if (datePickerRef.current) {
      const position = {
        top: datePickerY,
        left: datePickerX + datePickerMenuWidth + 3,
      };
      console.log('INTRAM IN IF!');

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const datePickerWidth = datePickerRef.current.offsetWidth;
      const datePickerHeight = datePickerRef.current.offsetHeight;

      // Adjust if the div goes out of the viewport to the right
      if (datePickerX + datePickerWidth > viewportWidth) {
        position.left = datePickerX - datePickerWidth - 3;
      }

      // Adjust if the div goes out of the viewport at the bottom
      if (datePickerY + datePickerHeight > viewportHeight) {
        position.top = viewportHeight - datePickerHeight - 5;
      }

      setPositionDatePicker(position);
    }
  }, [datePicker]);

  const [datePickerDate, setDatePickerDate] = useState(
    scheduled_date ? scheduled_date : new Date()
  );
  useEffect(() => {
    setDatePickerDate(scheduled_date ? scheduled_date : new Date());
  }, [datePicker]);
  const datePickerDateTimeframes = getFormattedDate(datePickerDate);
  // console.log({ datePickerDateTimeframes });

  const { loading } = useSelector((state) => state.loading);

  const handleRemoveDate = async (e) => {
    e.stopPropagation();

    dispatch(startLoading('Removing date...'));

    try {
      const removeDatePromise = await updateTask({
        id: taskId,
        data: { scheduled_date: null, scheduled_type: null },
      });

      dispatch(
        updateTaskInTasks({
          id: taskId,
          changes: { scheduled_date: null, scheduled_type: null },
        })
      );
      dispatch(removeTaskFromColumn({ columnId: columnId, taskId: taskId }));
      dispatch(closeDropDownMenu());
    } catch (error) {
      dispatch(
        setError({
          message: "Can't remove date from task, please try again.",
          error: error,
        })
      );
    } finally {
      dispatch(finishLoading());
    }
  };

  const handlePreviousClick = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === 'Y') {
      setDatePickerDate(addYears(datePickerDate, -1));
    } else if (type === 'M') {
      setDatePickerDate(addMonths(datePickerDate, -1));
    }
  };

  const handleForwardClick = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === 'Y') {
      setDatePickerDate(addYears(datePickerDate, +1));
    } else if (type === 'M') {
      setDatePickerDate(addMonths(datePickerDate, +1));
    }
  };

  const handleSelectDateClick = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(startLoading('Changing date...'));

    let columnIdSelected = '';
    if (type === 'D') {
      columnIdSelected = getFormattedDay(e.target.id).id;
    } else if (type === 'W') {
      columnIdSelected = getFormattedWeek(e.target.id).id;
    } else if (type === 'Today') {
      columnIdSelected = getFormattedDay(new Date()).id;
    } else if (type === 'Tomorrow') {
      columnIdSelected = getFormattedDay(addDays(new Date(), +1)).id;
    } else if (type === 'ThisWeek') {
      columnIdSelected = getFormattedWeek(new Date()).id;
    } else {
      columnIdSelected = datePickerDateTimeframes[type].id;
    }

    const convertedSelectedIdToDate = idToScheduled(columnIdSelected);
    const changes = {
      scheduled_date: convertedSelectedIdToDate.scheduled_date,
      scheduled_type: convertedSelectedIdToDate.scheduled_type,
      columnId: columnIdSelected,
    };

    try {
      const updateDatePromise = await updateTask({
        id: taskId,
        data: changes,
      });

      dispatch(
        updateTaskInTasks({
          id: taskId,
          changes: changes,
        })
      );
      dispatch(removeTaskFromColumn({ columnId: columnId, taskId: taskId }));
      dispatch(addTaskToColumn({ columnId: columnIdSelected, taskId: taskId }));

      dispatch(closeDropDownMenu());
    } catch (error) {
      dispatch(
        setError({
          message: "Can't update date for task, please try again.",
          error: error,
        })
      );
    } finally {
      dispatch(finishLoading());
    }
  };

  const renderTableCells = () => {
    let rows = [];
    let cells = [];

    // Get the start and end of the month
    const monthStart = startOfMonth(datePickerDate);
    const monthEnd = endOfMonth(datePickerDate);

    // Get the start of the week that contains the first day of the month
    const firstDayOfWeek = startOfWeek(monthStart, { weekStartsOn: 1 });

    let day = firstDayOfWeek;
    let formattedDate = '';

    while (day <= monthEnd) {
      // Add the week number as the first column in the row
      const weekNumber = getWeek(day, { weekStartsOn: 1 });
      cells.push(
        <td
          key={`week-${weekNumber}`}
          className='bg-red border-r border-stone-400 p-0 text-center'
        >
          <button
            id={day}
            onClick={(e) => {
              handleSelectDateClick(e, 'W');
            }}
            disabled={loading}
            className={`p-2 w-full text-center 
                transition duration-300 hover:bg-stone-300
                disabled:text-stone-500
                ${
                  scheduled_type === 'W' &&
                  isSameWeek(scheduled_date, day, { weekStartsOn: 1 })
                    ? 'bg-sky-500 text-white'
                    : ''
                }
                `}
          >
            {weekNumber}
          </button>
        </td>
      );

      // Start a new row for each week
      for (let i = 0; i < 7; i++) {
        if (isSameMonth(day, monthStart)) {
          formattedDate = format(day, 'd');
          cells.push(
            <td key={day} className={`${i === 0 ? 'pl-3' : ''} text-center`}>
              <button
                id={day}
                disabled={loading}
                onClick={(e) => {
                  handleSelectDateClick(e, 'D');
                }}
                className={`
                  disabled:text-stone-500
                ${i === 5 || i === 6 ? 'text-stone-500 ' : ''} 
                ${isToday(day) ? 'border border-dashed border-stone-400' : ''}
                ${
                  scheduled_type === 'D' && isSameDay(day, scheduled_date)
                    ? 'bg-sky-500 text-white'
                    : ''
                }
                 w-7 h-7 justify-center items-center text-center
                 transition duration-300 hover:bg-stone-300 rounded-full`}
              >
                {formattedDate}
              </button>
            </td>
          );
        } else {
          cells.push(<td key={day} className='p-0' />);
        }
        day = addDays(day, 1);
      }
      rows.push(<tr key={day}>{cells}</tr>);
      cells = [];
    }

    return rows;
  };
  return (
    datePicker && (
      <div
        onClick={(e) => e.stopPropagation()}
        ref={datePickerRef}
        style={positionDatePicker && !isMobile ? positionDatePicker : {}}
        className={`
          ${!positionDatePicker ? 'opacity-0' : ''}
          absolute z-30 rounded-md shadow-md border-stone-300 border bg-stone-200 text-black text-sm transition duration-300
          left-1/2 transform -translate-x-1/2 sm:-translate-x-0 bottom-0 sm:bottom-auto sm:top-auto sm:left-auto
          `}
      >
        <div className='flex justify-between'>
          <button
            onClick={(e) => handlePreviousClick(e, 'Y')}
            className='p-2 pl-3 pr-3 m-2 mr-1 rounded-md
            transition duration-300 hover:bg-stone-300'
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            disabled={loading}
            onClick={(e) => handleSelectDateClick(e, 'Y')}
            className={`grow p-2 m-2 ml-0 mr-0 rounded-md 
            disabled:text-stone-500
            transition duration-300 hover:bg-stone-300
            ${
              scheduled_type === 'Y' &&
              isSameYear(scheduled_date, datePickerDate, { weekStartsOn: 1 })
                ? 'bg-sky-500 text-white'
                : ''
            }
            `}
          >
            {datePickerDateTimeframes.Y.pretty}
          </button>
          <button
            onClick={(e) => handleForwardClick(e, 'Y')}
            className={`p-2 pl-3 pr-3 m-2 ml-1 rounded-md 
            transition duration-300 hover:bg-stone-300
            `}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>

        <div className='flex justify-between border-t border-stone-400'>
          <button
            onClick={(e) => handlePreviousClick(e, 'M')}
            className='p-2 pl-3 pr-3 m-2 mr-1 rounded-md 
            transition duration-300 hover:bg-stone-300'
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            disabled={loading}
            onClick={(e) => handleSelectDateClick(e, 'M')}
            className={`grow p-2 m-2 ml-0 mr-0 rounded-md 
            disabled:text-stone-500
            transition duration-300 hover:bg-stone-300
            ${
              scheduled_type === 'M' &&
              isSameMonth(scheduled_date, datePickerDate, { weekStartsOn: 1 })
                ? 'bg-sky-500 text-white'
                : ''
            }
            `}
          >
            {datePickerDateTimeframes.M.prettyShort}
          </button>
          <button
            onClick={(e) => handleForwardClick(e, 'M')}
            className='p-2 pl-3 pr-3 m-2 ml-1 rounded-md 
            transition duration-300 hover:bg-stone-300'
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>

        <div className='flex justify-center border-t border-stone-400 border-solid'>
          <button
            disabled={loading}
            onClick={(e) => handleSelectDateClick(e, 'Today')}
            className={`p-2 grow
            transition duration-300 hover:bg-stone-300 
            disabled:text-stone-500
            ${
              scheduled_type === 'D' && isToday(scheduled_date)
                ? 'bg-sky-500 text-white'
                : ''
            }
            `}
          >
            Today
          </button>
          <button
            disabled={loading}
            onClick={(e) => handleSelectDateClick(e, 'Tomorrow')}
            className={`p-2 grow rounded-sm
            transition duration-300 hover:bg-stone-300 
            disabled:text-stone-500
            ${
              scheduled_type === 'D' && isTomorrow(scheduled_date)
                ? 'bg-sky-500 text-white'
                : ''
            }
            `}
          >
            Tomorrow
          </button>
          <button
            disabled={loading}
            onClick={(e) => handleSelectDateClick(e, 'ThisWeek')}
            className={`p-2 grow
            transition duration-300 hover:bg-stone-300
            disabled:text-stone-500
            ${
              scheduled_type === 'W' &&
              isThisWeek(scheduled_date, { weekStartsOn: 1 })
                ? 'bg-sky-500 text-white'
                : ''
            }
             `}
          >
            This Week
          </button>
        </div>

        <div className='border-t border-stone-400'>
          <table>
            <thead>
              <tr className='uppercase text-stone-500 text-xs'>
                <th className='text-center p-1 pl-3 pr-3 border-r border-stone-400'>
                  Week
                </th>
                <th className='pl-3'>Mon</th>
                <th className='p-1 w-9'>Tue</th>
                <th className='p-1 w-9'>Wed</th>
                <th className='p-1 w-9'>Thu</th>
                <th className='p-1 w-9'>Fri</th>
                <th className='p-1 w-9'>Sat</th>
                <th className=' p-1 w-9'>Sun</th>
              </tr>
            </thead>
            <tbody>{renderTableCells()}</tbody>
          </table>
        </div>

        {scheduled_date && (
          <button
            onClick={handleRemoveDate}
            disabled={loading}
            className={`flex items-center justify-center w-full p-2 border-t border-red-400 border-solid
           transition duration-300 bg-red-200 hover:bg-red-400 hover:border-red-600 rounded-b-md
           disabled:text-stone-500
           `}
          >
            Remove Date
          </button>
        )}
      </div>
    )
  );
};
export default DatePicker;
