import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import Task from '../components/Task/Task';
import { PAGES } from '../utils/constants';
import { RootState } from '../state/store'; // You'll need to create this type

interface Column {
  title: string;
  tasks: string[];
}

export default function AllTasksPage() {
  const tasksData = useSelector((state: RootState) => state.tasks);
  const [visibleTasks, setVisibleTasks] = useState<number[]>([25, 25, 25, 25]);

  const columns: Column[] = [
    { title: 'Most Important', tasks: [] },
    { title: 'Unplanned & Open', tasks: [] },
    { title: 'Unorganized & Open', tasks: [] },
    { title: 'Recent Tasks', tasks: [] },
  ];

  const sortedTasksByParentsLength = Object.keys(tasksData).sort((a, b) => {
    return tasksData[b].parents.length - tasksData[a].parents.length;
  });

  sortedTasksByParentsLength.forEach((key) => {
    columns[0].tasks.push(key);
  });

  Object.keys(tasksData)
    .reverse()
    .forEach((key) => {
      columns[3].tasks.push(key);
      if (
        tasksData[key].scheduled_date === null &&
        tasksData[key].status === 0
      ) {
        columns[1].tasks.push(key);
      }
      if (tasksData[key].parents.length === 0 && tasksData[key].status === 0) {
        columns[2].tasks.push(key);
      }
    });

  return (
    <div className='space flex h-full content-stretch relative'>
      {columns.map((column, index) => {
        return (
          <div key={index} className=' flex-1 flex flex-col p-1 pb-0 pl-3 pr-3'>
            <div className='text-2xl font-bold mb-2'>
              {column.title}: {column.tasks.length}
              {index === 0 && (
                <div className='text-sm font-normal italic text-stone-400'>
                  By having multiple parents, these tasks have the most reasons
                  to get done as you can{' '}
                  <span
                    className='underline'
                    data-tooltip-id='all-tasks-tooltip'
                    data-tooltip-html="
                      <div>🇪🇸 Matar dos pájaros de un tiro</div>
                      <div>🇫🇷 Faire d'une pierre deux coups</div>
                      <div>🇮🇹 Prendere due piccioni con una fava</div>
                      <div>🇬🇷 Με ένα σμπάρο, δυο τρυγόνια (Me éna smpáro, dio trygónia)</div>
                      <div>🇹🇷 Bir taşla iki kuş vurmak</div>
                      <div>🇯🇵 一石二鳥 (いっせきにちょう, Isseki nichō)</div>
                      <div>🇸🇦 أصاب عصفورين بحجر واحد (Asaba 'usfoorayn bihajar wahid)</div>
                      <div>🇮🇷 এক ঢিলে দুই পাখি মারা (Ek dhile dui pakhi mara)</div>
                      <div>🇮🇳 ఒక రాతితో రెండు పక్షులను కొట్టడం (Oka ratito rendu pakshulanu kottadam)</div>
                      <div>🇮🇳 ஒரே கல்லால் இரண்டு பறவைகளை அடிக்க (Ore kallal irandu paravaigalai adikka)</div>
                      <div>🇮🇳 एका दगडात दोन पक्षी मारणे (Eka dagadat don pakshi marne)</div>

                      <div class='mt-2'>Kill two rabbits with one blow</div>
                      <div>🇵🇹 Matar dois coelhos com uma cajadada só</div>
                      <div>🇷🇴 Împuști doi iepuri dintr-o lovitură</div>
                      <div>🇷🇺 Убить двух зайцев одним выстрелом (Ubit' dvukh zaytsev odnim vystrelom)</div>
                      <div>🇺🇦 Вбити двох зайців одним пострілом (Vbyty dvokh zaĭtsiv odnym postrilom)</div>
                      <div>🇧🇬 Убивам два заека с един куршум (Ubivam dva zaeka s edin kurshum)</div>
                      
                      <div class='mt-2'>Hit two flies with one swat.</div>
                      <div>🇩🇪 Zwei Fliegen mit einer Klappe schlagen</div>
                      <div>🇭🇺 Két legyet üt egy csapásra</div>
                      <div>🇷🇸 Убити две муве једним ударцем (Ubiti dve muve jednim udarcem)</div>
                      
                      <div class='mt-2'>Shoot two eagles with one arrow</div>
                      <div>🇨🇳 箭双雕 (Yī jiàn shuāng diāo)</div>

                      <div class='mt-2'>Hit two targets with one arrow.</div>
                      <div>🇮🇷 با یک تیر دو نشان زدن (Bâ yek tir do neshân zadan)</div>
                      <div>🇮🇳 एक तीर से दो निशाने लगाना (Ek teer se do nishane lagana)</div>
                      <div>🇮🇳 ایک تیر سے دو شکار کرنا (Ek teer se do shikar karna)</div>
                      "
                  >
                    kill multiple birds with one stone
                  </span>
                  .
                </div>
              )}
            </div>

            <div className='overflow-y-scroll h-full no-scrollbar'>
              {column.tasks.slice(0, visibleTasks[index]).map((taskId) => {
                return (
                  <Task
                    key={taskId}
                    taskId={taskId}
                    taskNestLevel={0}
                    space={PAGES.ALLTASKS}
                  />
                );
              })}
              {visibleTasks[index] < column.tasks.length && (
                <button
                  onClick={() => {
                    setVisibleTasks((prevVisibleTasks) => {
                      // Create a copy of the previous state
                      const updatedVisibleTasks = [...prevVisibleTasks];
                      // Increment the number of visible tasks for the clicked column
                      updatedVisibleTasks[index] =
                        updatedVisibleTasks[index] + 25; // or whatever increment value you want
                      return updatedVisibleTasks;
                    });
                  }}
                  className='text-sm mt-1 text-stone-700 p-2 text-center w-full italic rounded-md mb-2
                    bg-stone-100 hover:bg-stone-200'
                >
                  Show More
                </button>
              )}
            </div>
          </div>
        );
      })}
      <Tooltip id='all-tasks-tooltip' />
    </div>
  );
}
