import { createSlice } from '@reduxjs/toolkit';

// Define a type for the state
type KeysState = {
  identifier: string;
  pw_nonce: string;
  masterKey: string;
  encryptedItemsKeys: Record<string, string>; // Adjust the type as necessary
  encryptedItemsKeyId: string;
};

// Define initial state with the KeysState type
const initialState: KeysState = {
  identifier: '',
  pw_nonce: '',
  masterKey: '',
  encryptedItemsKeys: {},
  encryptedItemsKeyId: '',
};

const keysSlice = createSlice({
  name: 'keys',
  initialState,
  reducers: {
    setKeys: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        if (key in state) {
          // Changed to 'key in state' for better type safety
          state[key as keyof KeysState] = action.payload[key]; // Type assertion
        }
      });
      console.log({ action });
    },
  },
});

export const { setKeys } = keysSlice.actions;
export default keysSlice.reducer;
