import { useSelector } from 'react-redux';
import { encryptTitleOrDescription } from '../utils/encryption';
import { updateTask } from '../api/taskApi';
import { exportJSONToTxt } from '../utils/exportJSONToTxt';

export default function AccountPage() {
  const tasksData = useSelector((state) => state.tasks);
  const {
    masterKey,
    encryptedItemsKeys,
    encryptedItemsKeyId,
    identifier,
    pw_nonce,
  } = useSelector((state) => state.keys);

  const handleChangePasswordClick = () => {};

  const handleEncryptCurrentTasksClick = async () => {
    alert(
      'Dezactivat! Mai intai baga title si description din api sa vina iar ca sa ai ce cripta.'
    );
    return;

    Object.keys(tasksData).forEach(async (taskId) => {
      const encryptedTitle = await encryptTitleOrDescription(
        tasksData[taskId].title,
        masterKey,
        encryptedItemsKeys,
        encryptedItemsKeyId
      );
      const encryptedDescription = tasksData[taskId].description
        ? await encryptTitleOrDescription(
            tasksData[taskId].description,
            masterKey,
            encryptedItemsKeys,
            encryptedItemsKeyId
          )
        : null;
      // console.log('Title: ' + tasksData[taskId].title);
      // console.log('Criptat: ' + encryptedTitle.contentEncrypted);
      // console.log('Description: ' + tasksData[taskId].description);
      // if (tasksData[taskId].description) {
      //   console.log(
      //     'Description Criptat: ' + encryptedDescription.contentEncrypted
      //   );
      // }
      console.log('---');
      // if (tasksData[taskId].description !== null) {
      //   console.log('Description: ' + tasksData[taskId].description);
      // }

      let dataForApi = {
        title_encrypted: encryptedTitle.contentEncrypted,
        title_item_key_encrypted: encryptedTitle.contentItemKeyEncrypted,
        title_items_key_id: encryptedItemsKeyId,
      };
      if (tasksData[taskId].description) {
        dataForApi['description_encrypted'] =
          encryptedDescription.contentEncrypted;
        dataForApi['description_item_key_encrypted'] =
          encryptedDescription.contentItemKeyEncrypted;
        dataForApi['description_items_key_id'] = encryptedItemsKeyId;
      }

      console.log({ dataForApi });

      await updateTask({ id: taskId, data: dataForApi });
    });
  };

  const handleExportClick = (type) => {
    //check if tasksData is empty or not
    if (Object.keys(tasksData).length === 0) {
      alert("You haven't created any tasks yet!");
      return;
    }

    let exportTasks = [];
    Object.keys(tasksData).forEach((taskId) => {
      const currentTask = { ...tasksData[taskId] };
      if (type === 'encrypted') {
        delete currentTask['title'];
        delete currentTask['description'];
      } else if (type === 'decrypted') {
        delete currentTask['title_encrypted'];
        delete currentTask['title_item_key_encrypted'];
        delete currentTask['title_items_key_id'];
        delete currentTask['description_encrypted'];
        delete currentTask['description_item_key_encrypted'];
        delete currentTask['description_items_key_id'];
      }
      exportTasks.push(currentTask);
    });

    if (type === 'encrypted') {
      exportJSONToTxt({
        tasks: exportTasks,
        keyParams: {
          identifier,
          pw_nonce,
        },
        itemsKeys: {
          encryptedItemsKeys,
        },
      });
    } else if (type === 'decrypted') {
      exportJSONToTxt({
        tasks: exportTasks,
      });
    }
  };

  return (
    <div className='flex flex-col items-center justify-center w-full '>
      <div className='bg-stone-100 p-2 border border-stone-300 rounded w-full max-w-md my-2'>
        <h2 className='font-semibold mb-2'>Passcode</h2>
      </div>

      {/* <button
        onClick={handleEncryptCurrentTasksClick}
        className='p-1 px-2 mb-2 text-sm border rounded border-stone-400'
      >
        (Temporary Dev) Encrypt current tasks
      </button> */}

      <div className='bg-stone-100 mb-2 p-2 border border-stone-300 rounded w-full max-w-md'>
        <h2 className='font-semibold mb-2'>Export</h2>
        <div className='text-sm'>
          Download all your tasks in{' '}
          <span className='font-semibold'>encrypted</span> format to have them
          as a backup.
        </div>
        <button
          onClick={() => handleExportClick('encrypted')}
          className='p-1 px-2 mt-2 mb-2 text-sm border rounded border-stone-400'
        >
          Download encrypted tasks
        </button>
        <div className='italic mb-3 text-sm text-stone-600'>
          If the internet is down for any reason or it doesn't exist anymore,
          you can decrypt your tasks offline on your computer with{' '}
          <a href='#' className='font-semibold text-stone-800'>
            PerspecTask Offline Tool
          </a>{' '}
          (Coming soon!) <br></br>Download this tool now so that you already
          have it on your computer or usb stick in case you don't have access to
          the internet.
        </div>

        <div className='border-t border-stone-300 mb-3'></div>
        <p className='text-sm'>
          If you want to export your tasks in{' '}
          <span className='font-semibold'>plain text (decrypted)</span> format
          for personal use or to import them in a task management, you can
          export them in decrypted format.
        </p>
        <button
          onClick={() => handleExportClick('decrypted')}
          className='p-1 px-2 mt-2 bg-orange-200 text-sm border rounded border-stone-400'
        >
          Download Decrypted(!) Tasks
        </button>
      </div>

      <div className='bg-stone-100 p-2 border border-stone-300 rounded w-full max-w-md mb-2'>
        <h2 className='font-semibold mb-2'>Email</h2>
      </div>

      <div className='bg-stone-100 p-2 mb-2 border border-stone-300 rounded w-full max-w-md'>
        <h2 className='font-semibold mb-2'>Password</h2>
        <p className='text-sm'>
          Current password was set on{' '}
          <span className='font-semibold'>date</span>.
        </p>
        <button
          onClick={handleChangePasswordClick}
          className='p-1 px-2 mt-2 text-sm border rounded border-stone-400'
        >
          Change password
        </button>
      </div>
    </div>
  );
}
