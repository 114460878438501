import { configureStore } from '@reduxjs/toolkit';
import tasksSlice from './tasksSlice';
import columnsSlice from './columnsSlice';
import loadingSlice from './loadingSlice';
import modalSlice from './modalSlice';
import displayDateSlice from './displayDateSlice';
import dropDownMenuSlice from './dropDownMenuSlice';
import searchMenuSlice from './searchMenuSlice';
import transportationSlice from './transportationSlice';
import keysSlice from './keysSlice';
import settingsSlice from './settingsSlice';
import mobileSlice from './mobileSlice';

const store = configureStore({
  reducer: {
    tasks: tasksSlice,
    columns: columnsSlice,
    loading: loadingSlice,
    modal: modalSlice,
    displayDate: displayDateSlice,
    dropDownMenu: dropDownMenuSlice,
    searchMenu: searchMenuSlice,
    transportation: transportationSlice,
    keys: keysSlice,
    settings: settingsSlice,
    mobile: mobileSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
