import { useDispatch } from 'react-redux';
import { openDropDownMenu } from '../../state/dropDownMenuSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

export default function TaskMore({ taskId }: { taskId: string }) {
  const dispatch = useDispatch();

  const handleMoreClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(openDropDownMenu({ taskId: taskId, x: e.clientX, y: e.clientY }));
  };

  return (
    <button
      data-tooltip-id='modal-tooltip'
      data-tooltip-content='More...'
      data-tooltip-place='bottom'
      className={` h-7 w-7 flex items-center justify-center rounded-full 
transition duration-300 hover:bg-stone-400 group-hover/task:opacity-100
`}
      onClick={handleMoreClick}
    >
      <FontAwesomeIcon icon={faEllipsis} className='text-stone-800' />
    </button>
  );
}
