import { createSlice } from '@reduxjs/toolkit';
const closeDatePickerFunction = (state) => {
  state.datePicker = false;
  state.datePickerX = 0;
  state.datePickerY = 0;
  state.datePickerMenuWidth = 0;
};
const dropDownMenuSlice = createSlice({
  name: 'dropDownMenu',
  initialState: {
    taskId: null,
    menu: false,
    menuX: 0,
    menuY: 0,
    datePicker: false,
    datePickerX: 0,
    datePickerY: 0,
    datePickerMenuWidth: 0,
  },
  reducers: {
    openDropDownMenu: (state, action) => {
      const { taskId, x, y } = action.payload;
      state.taskId = taskId;
      state.menuX = x;
      state.menuY = y;
      state.menu = true;
    },

    closeDropDownMenu: (state) => {
      state.taskId = null;
      state.menuX = 0;
      state.menuY = 0;
      state.menu = false;
      closeDatePickerFunction(state);
    },

    openDatePicker: (state, action) => {
      const { taskId, x, y, menuWidth = 0 } = action.payload;
      state.taskId = taskId;
      state.datePicker = true;
      state.datePickerX = x;
      state.datePickerY = y;
      state.datePickerMenuWidth = menuWidth;
    },

    closeDatePicker: (state) => {
      closeDatePickerFunction(state);
    },
  },
});

export const {
  openDropDownMenu,
  closeDropDownMenu,
  openDatePicker,
  closeDatePicker,
} = dropDownMenuSlice.actions;
export default dropDownMenuSlice.reducer;
