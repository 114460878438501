import TimelineNavigator from '../TimelineNavigator/TimelineNavigator';
import { PAGES } from '../../utils/constants';
import { useSelector } from 'react-redux';
import ColumnDroppable from './ColumnDroppable';

function Column({ perspective, spaceType, columnIndex }) {
  const { id, title, subtitle, isSameDate, space } = perspective;
  const columnId = id;

  const isSameDateInTimeline = isSameDate && space == spaceType;
  const isSameSpace = space == spaceType;
  const columnsData = useSelector((state) => state.columns);
  const tasksIds = columnsData[columnId] ?? [];
  return (
    <div
      key={columnId}
      className={`group/column flex-1 p-1 pl-3 pr-3 relative h-full overflow-hidden 
        ${spaceType === PAGES.DEFAULT ? 'min-w-72' : ''}
        ${
          spaceType !== PAGES.DEFAULT && !isSameSpace
            ? 'bg-gradient-to-r from-stone-50 via-white to-stone-50 perspective-context'
            : ''
        }
        ${
          columnIndex === 0 && spaceType !== PAGES.DEFAULT
            ? 'hidden lg:block'
            : ''
        }
        ${columnIndex === 1 && spaceType !== PAGES.DEFAULT ? '' : ''}
        ${
          columnIndex === 2 && spaceType !== PAGES.DEFAULT
            ? 'hidden md:block'
            : ''
        }
        ${
          columnIndex === 3 && spaceType !== PAGES.DEFAULT
            ? 'hidden xl:block'
            : ''
        }
        ${
          columnIndex === 4 && spaceType !== PAGES.DEFAULT
            ? 'hidden 2xl:block'
            : ''
        }
        ${
          columnIndex === 5 && spaceType !== PAGES.DEFAULT
            ? 'hidden sm:block'
            : ''
        }
        `}
    >
      <div className={`${isSameDateInTimeline ? 'text-orange-500' : ''}`}>
        <h1 className='text-4xl sm:text-2xl font-bold'>{title}</h1>
        <h2
          className={`text-lg  font-light leading-6 ${
            isSameDateInTimeline ? 'text-orange-500' : 'text-stone-400'
          }`}
        >
          {subtitle}
        </h2>
      </div>
      <ColumnDroppable columnId={columnId} tasksIds={tasksIds} space={space} />

      <div className='opacity-0 transition duration-300 group-hover/column:opacity-100'>
        {spaceType == PAGES.DEFAULT && space ? (
          <TimelineNavigator perspectiveType={space} />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default Column;
