import { createSlice } from '@reduxjs/toolkit';

const searchMenuSlice = createSlice({
  name: 'searchMenu',
  initialState: {
    isOpen: false,
  },
  reducers: {
    openSearchMenu: (state) => {
      state.isOpen = true;
    },
    closeSearchMenu: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openSearchMenu, closeSearchMenu } = searchMenuSlice.actions;
export default searchMenuSlice.reducer;
