import {
  faBan,
  faCheck,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  finishLoading,
  setError,
  startLoading,
} from '../../state/loadingSlice';
import { updateTask } from '../../api/taskApi';
import { updateTaskInTasks } from '../../state/tasksSlice';
import celebrate from '../../utils/celebrate';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function TaskCheckbox({ taskId }) {
  const tasksData = useSelector((state) => state.tasks);
  const { status, scheduled_type } = tasksData[taskId] || {};
  const [isChecking, setIsChecking] = useState(false);
  const dispatch = useDispatch();

  const handleCheckboxClick = async (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevents the click event from bubbling up to the <Link> component
    setIsChecking(true);
    dispatch(startLoading('Completing task...'));
    const delayPromise = delay(400);
    const updateTaskPromise = updateTask({
      id: taskId,
      data: { status: status ? 0 : 1 },
    });
    try {
      await Promise.all([updateTaskPromise, delayPromise]);
      if (!status) {
        celebrate(e, scheduled_type);
      }
      dispatch(
        updateTaskInTasks({ id: taskId, changes: { status: status ? 0 : 1 } })
      );
    } catch (error) {
      dispatch(
        setError({
          message: "Can't check task, please try again.",
          error: error,
        })
      );
    } finally {
      setIsChecking(false);
      dispatch(finishLoading());
    }
  };

  return (
    <button
      className={`group p-0.5 flex border rounded  w-full h-full
    ${status == 1 ? 'bg-stone-400 text-white' : 'bg-white text-stone-400'} 
    ${status == 2 ? '!bg-red-400 !text-white' : ''} 
    flex items-center justify-center`}
      onClick={handleCheckboxClick}
      disabled={isChecking || status === 2}
    >
      {status == 2 ? (
        <FontAwesomeIcon icon={faBan} className='w-3 h-3' />
      ) : isChecking ? (
        <FontAwesomeIcon icon={faCircleNotch} className='w-3 h-3' spin />
      ) : (
        <FontAwesomeIcon
          icon={faCheck}
          className={`w-3 h-3 transition-opacity duration-300 ease-in-out ${
            status == 1 ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
          }`}
        />
      )}
    </button>
  );
}
