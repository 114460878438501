import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    shortTitles: false,
    hideCompleted: false,
  },
  reducers: {
    setShortTitles: (state, action) => {
      state.shortTitles = action.payload;
    },
    setHideCompleted: (state, action) => {
      state.hideCompleted = action.payload;
    },
  },
});

export const { setShortTitles, setHideCompleted } = settingsSlice.actions;
export default settingsSlice.reducer;
