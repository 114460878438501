import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
export default function NotFoundPage() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate('/');
  //   }, 3000); // 3000 milliseconds = 3 seconds

  //   // Clean up the timer if the component unmounts before the timer finishes
  //   return () => clearTimeout(timer);
  // }, [navigate]);

  return (
    <div className='text-center m-4'>
      <div>Hmm, what are you looking for? 👀</div>
      <div>
        <Link to='/' className='text-blue-700'>
          Redirecting
        </Link>{' '}
        in 3 seconds...
      </div>
    </div>
  );
}
