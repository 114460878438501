function shortenText(
  text: string,
  tooltipId?: string,
  length: number = 20
): { isLong: boolean; tooltip: {}; value: string } {
  const result = {
    isLong: false,
    tooltip: {},
    value: text,
  };

  if (text.length > length) {
    result.isLong = true;
    result.value = `${text.slice(0, length - 2)}...`;
    result.tooltip = tooltipId
      ? {
          'data-tooltip-id': tooltipId,
          'data-tooltip-content': text,
        }
      : {};
  }

  return result;
}

export default shortenText;
