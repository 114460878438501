import React, { useRef, useState } from 'react';
import { postTask } from '../../api/taskApi';
import { useSelector, useDispatch } from 'react-redux';
import {
  finishLoading,
  setError,
  startLoading,
} from '../../state/loadingSlice';
import { addTaskToColumn } from '../../state/columnsSlice';
import { addTaskToTasks, combineTasksInTasks } from '../../state/tasksSlice';
import {
  generateSeed,
  encryptText,
  decryptText,
  encryptTitleOrDescription,
} from '../../utils/encryption';

function AddTask({ addTaskData, focusInput = false }) {
  const [taskText, setTaskText] = useState('');
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loading);
  const { masterKey, encryptedItemsKeyId, encryptedItemsKeys } = useSelector(
    (state) => state.keys
  );

  if (focusInput && inputRef.current) {
    inputRef.current.focus();
  }

  const handleClick = () => {
    console.log({ inputRef });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSubmit = async (refocusAfterSuccess) => {
    if (!masterKey || !encryptedItemsKeyId || !encryptedItemsKeys) {
      dispatch(
        setError({
          message: 'Please reinsert your password',
        })
      );
      return;
    }

    const trimmedTask = taskText.trim();

    const { contentEncrypted, contentItemKeyEncrypted } =
      await encryptTitleOrDescription(
        trimmedTask,
        masterKey,
        encryptedItemsKeys,
        encryptedItemsKeyId
      );

    if (trimmedTask && !loading) {
      dispatch(startLoading('Adding task...'));
      const postData = {
        ...addTaskData,
        // title: trimmedTask,
        title_encrypted: contentEncrypted,
        title_item_key_encrypted: contentItemKeyEncrypted,
        title_items_key_id: encryptedItemsKeyId,
      };
      console.log({ postData });

      try {
        const postedTask = await postTask({
          data: postData,
        });
        console.log({ postedTask });

        //TODO: fa sa bagi tot postedTask si sa returneze api-ul ce returneaza si la GET tasks
        dispatch(
          addTaskToTasks({
            id: postedTask.id,
            title: trimmedTask,
            description: '',
            status: 0,
            columnId: postedTask.columnId,
            scheduled_date: addTaskData.scheduled_date
              ? addTaskData.scheduled_date
              : null,
            scheduled_type: addTaskData.scheduled_type
              ? addTaskData.scheduled_type
              : null,
            children: [],
            parents: [],
          })
        );
        setTaskText('');

        if (addTaskData['parent_id']) {
          dispatch(
            combineTasksInTasks({
              parentId: addTaskData['parent_id'],
              childId: postedTask.id,
            })
          );
        }
        if (addTaskData['column_id']) {
          dispatch(
            addTaskToColumn({
              columnId: addTaskData['column_id'],
              taskId: postedTask.id,
            })
          );
        }
      } catch (error) {
        dispatch(
          setError({
            message: 'Error adding the task, please try again.',
            error: error,
          })
        );
      } finally {
        dispatch(finishLoading());

        if (refocusAfterSuccess && inputRef.current) {
          // Make sure the input is enabled
          inputRef.current.disabled = false;
          inputRef.current.focus();
        }
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission or default behavior
      handleSubmit(true);
    }
  };

  const handleBlur = () => {
    handleSubmit();
  };

  return (
    <label
      className='group relative block grow cursor-pointer ' /*mb-14*/
      onClick={handleClick}
    >
      <div
        className='
          flex items-baseline rounded-md bg-white border border-white
          group-hover:bg-stone-200 group-hover:border-stone-200
          group-hover:focus-within:bg-white
          focus-within:border focus-within:border-stone-300 focus-within:bg-white focus-within:shadow-md'
      >
        <span className='sr-only'>Add task</span>
        <span className='flex items-center pl-1 mr-1'>
          {/* <input type='checkbox' disabled='true' /> */}
          <button
            className=' p-0.5 border rounded bg-white text-stone-400 flex items-center justify-center'
            disabled={true}
          >
            <span className='w-3 h-3 opacity-0' />
          </button>
        </span>

        <input
          disabled={loading}
          ref={inputRef}
          value={taskText}
          onChange={(e) => setTaskText(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          className='
            sm:text-sm block p-1 pl-0 pr-7 cursor-pointer w-full overflow-hidden rounded-md
            disabled:bg-white 
            group-hover:placeholder:text-stone-500 group-hover:bg-stone-200 group-hover:focus:bg-white
            hover:bg-stone-200 
            placeholder:text-stone-400 
            focus:outline-none focus:cursor-auto'
          placeholder='Add...'
          type='text'
          name='addTask'
        />
      </div>
    </label>
  );
}

export default AddTask;
