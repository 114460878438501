import { isToday } from 'date-fns';
import { add, sub } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { setDisplayDate } from '../../state/displayDateSlice';
import { RootState } from '../../state/store';
import { PAGES } from '../../utils/constants';

export default function TimelineNavigator({
  perspectiveType,
}: {
  perspectiveType: string;
}) {
  const displayDate = useSelector(
    (state: RootState) => state.displayDate.displayDate
  );
  const transportationData = useSelector(
    (state: RootState) => state.transportation
  );

  const dispatch = useDispatch();

  const changeTimeline = (
    direction: 'today' | 'forward' | 'backward',
    period?: string
  ) => {
    if (direction == 'today') {
      dispatch(setDisplayDate(new Date().setHours(0, 0, 0, 0)));
      return;
    }
    if (!period) {
      return;
    }
    const operation = direction === 'forward' ? add : sub;
    const periodLower = period.toLowerCase();
    dispatch(
      setDisplayDate(
        operation(displayDate, { [periodLower]: 1 }).setHours(0, 0, 0, 0)
      )
    );
  };

  return (
    <div
      className={`absolute  left-1/2 transform -translate-x-1/2 rounded-md m-1 !border-0 flex cursor-pointer bg-stone-100 shadow-inner
        ${transportationData.taskId ? 'bottom-14' : 'bottom-1'}
    `}
    >
      <button
        className='hover:bg-slate-300 rounded-l-md
        p-2 pl-6 pr-6 sm:p-1 sm:pr-2 sm:pl-2
        text-2xl sm:text-base'
        onClick={() => changeTimeline('backward', perspectiveType)}
      >
        &#9204;
      </button>
      <button
        className={`${
          isToday(displayDate)
            ? 'text-stone-300 cursor-default'
            : 'text-stone-800 hover:bg-slate-300'
        } 
        p-2 pl-4 pr-4 sm:p-1 sm:pr-1 sm:pl-1
        text-xl sm:text-base`}
        onClick={() => {
          if (!isToday(displayDate)) {
            changeTimeline('today');
          }
        }}
      >
        Today
      </button>
      <button
        className='hover:bg-slate-300 rounded-r-md
        p-2 pl-6 pr-6 sm:p-1 sm:pr-2 sm:pl-2
        text-2xl sm:text-base'
        onClick={() => changeTimeline('forward', perspectiveType)}
      >
        &#9205;
        {/* &#10148; */}
      </button>
    </div>
  );
}
