import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import Task from '../components/Task/Task';
import { PAGES } from '../utils/constants';

export default function ReflectionPage() {
  const tasksData = useSelector((state) => state.tasks);
  const periodEnd = new Date();
  //yesterday date
  const periodStart = new Date() - 1000 * 60 * 60 * 24 * 7;

  //get tasks that have status_date from periodStart to periodEnd
  const tasksDone = Object.values(tasksData).filter((task) => {
    const statusDate = new Date(task.status_date);
    return statusDate >= periodStart && statusDate <= periodEnd;
  });
  console.log({ tasksDone });

  return (
    <div className=''>
      <div>Reflection</div>

      <div>
        <div>Period change</div>
        <div>Tasks done: {tasksDone.length}</div>
        {tasksDone.map((task) => (
          <Task
            key={task.id}
            taskId={task.id}
            taskNestLevel={0}
            space={PAGES.ALLTASKS}
          />
        ))}
      </div>

      <Tooltip id='reflection-tooltip' />
    </div>
  );
}
