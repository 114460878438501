import { useDispatch, useSelector } from 'react-redux';
import TaskCheckbox from './TaskCheckbox';
import TextareaAutosize from 'react-textarea-autosize';
import {
  finishLoading,
  setError,
  startLoading,
} from '../../state/loadingSlice';
import { updateTask } from '../../api/taskApi';
import { updateTaskInTasks } from '../../state/tasksSlice';
import { useEffect, useRef, useState } from 'react';
import ShortcutLabel from '../Helpers/ShortcutLabel';
import { encryptTitleOrDescription } from '../../utils/encryption';

export default function TaskEdit({ taskId }) {
  const tasksData = useSelector((state) => state.tasks);
  const { title, description, status } = tasksData[taskId] || {};
  const { loading } = useSelector((state) => state.loading);
  const { masterKey, encryptedItemsKeys, encryptedItemsKeyId } = useSelector(
    (state) => state.keys
  );

  const [taskTitle, setTaskTitle] = useState(title);
  const [taskDescription, setTaskDescription] = useState(description);

  const descriptionRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (title) {
      setTaskTitle(title);
    }
  }, [title]);

  useEffect(() => {
    if (description) {
      setTaskDescription(description);
    } else {
      setTaskDescription('');
    }
  }, [description, taskId]);

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.blur();
    }
  }, [taskId]);

  const handleKeyPress = (e, textareaType) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // To prevent the default behavior of moving to a new line
      handleSubmit(textareaType, e.target.value);
    }
  };

  const handleBlur = (textareaType, content) => {
    if (textareaType === 'title') {
      handleSubmit(textareaType, content);
    }
  };

  const handleSubmit = async (textareaType, content) => {
    if (!masterKey || !encryptedItemsKeyId || !encryptedItemsKeys) {
      dispatch(
        setError({
          message: 'Please reinsert your password',
        })
      );
      return;
    }

    let savedValue;
    content = content.trim();

    if (textareaType === 'title') {
      if (content.length === 0) {
        setTaskTitle(title);
        console.log('Title is empty, skipping update.');
        return;
      }
      savedValue = title;
    } else if (textareaType === 'description') {
      savedValue = description;
    }
    if (savedValue === content) {
      console.log('No changes detected, skipping update.');
      return;
    }

    // Encrypt content
    const { contentEncrypted, contentItemKeyEncrypted } =
      await encryptTitleOrDescription(
        content,
        masterKey,
        encryptedItemsKeys,
        encryptedItemsKeyId
      );

    const dataForApi = {
      // [textareaType]: content,
      [textareaType === 'title' ? 'title_encrypted' : 'description_encrypted']:
        contentEncrypted,
      [textareaType === 'title'
        ? 'title_item_key_encrypted'
        : 'description_item_key_encrypted']: contentItemKeyEncrypted,
      [textareaType === 'title'
        ? 'title_items_key_id'
        : 'description_items_key_id']: encryptedItemsKeyId,
    };

    const dataForRedux = {
      [textareaType]: content,
    };
    // console.log({ dataForRedux, dataForApi });
    dispatch(startLoading('Updating task...'));
    try {
      await updateTask({ id: taskId, data: dataForApi });
      dispatch(updateTaskInTasks({ id: taskId, changes: dataForRedux }));
    } catch (error) {
      dispatch(
        setError({
          message: "Can't update task, please try again.",
          error: error,
        })
      );
    } finally {
      dispatch(finishLoading());
    }
  };
  const handleCancelDescriptionClick = () => {
    setTaskDescription(description);
  };

  const handleApplyDescriptionClick = () => {
    handleSubmit('description', taskDescription);
  };

  return (
    <div
      className={`p-3 pb-1 pt-0 gap-0
        ${
          status === 2
            ? 'text-red-500 line-through cursor-not-allowed'
            : status == 1
            ? 'cursor-not-allowed'
            : ''
        }
        `}
      {...(status !== 0
        ? {
            'data-tooltip-id': 'task-tooltip',
            'data-tooltip-place': 'bottom-start',
          }
        : {})}
      {...(status === 2
        ? {
            'data-tooltip-content':
              'You need to reactivate this task to be able to edit it again.',
          }
        : status == 1
        ? {
            'data-tooltip-content':
              'You need to uncheck this task to be able to edit it again.',
            'data-tooltip-delay-show': 500,
          }
        : {})}
    >
      <div className='relative flex flex-row items-baseline mb-1.5 ml-2'>
        <div className='w-6 h-6 flex items-center justify-center '>
          <TaskCheckbox taskId={taskId} />
        </div>
        <TextareaAutosize
          className={`bg-transparent ring-0 text-2xl font-bold ml-2 p-2 pb-1 pt-1 border-0 rows-1 w-full h-auto outline-none resize-none overflow-hidden rounded-md
              focus:border-stone-200 focus:ring-stone-300 focus:ring-1  focus:shadow-md
              ${
                status === 2
                  ? 'text-red-500 line-through cursor-not-allowed'
                  : status == 1
                  ? 'cursor-not-allowed'
                  : ''
              }
              ${status == 1 ? 'text-stone-400' : ''}
              `}
          value={taskTitle}
          onChange={(e) => setTaskTitle(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e, 'title')}
          onBlur={(e) => handleBlur('title', e.target.value)}
          disabled={loading || status !== 0}
        ></TextareaAutosize>
        <div
          className={`absolute right-1 bottom-1 transition duration-300
            ${title !== taskTitle ? 'opacity-30' : 'opacity-0'}
            `}
        >
          <ShortcutLabel genericKey={'Enter'} />
        </div>
      </div>
      <TextareaAutosize
        ref={descriptionRef}
        className={`outline-none ring-0 w-full resize-none bg-transparent text-stone-500 rounded-md p-2 pb-1 pt-1
            focus:border-stone-200 focus:ring-stone-300 focus:ring-1  focus:shadow-md
            ${
              status === 2
                ? '!text-red-500 line-through cursor-not-allowed'
                : status == 1
                ? 'cursor-not-allowed'
                : ''
            }
            `}
        value={taskDescription}
        onChange={(e) => setTaskDescription(e.target.value)}
        placeholder='≡ Description'
        disabled={loading || status !== 0}
      ></TextareaAutosize>
      <div
        className={`
           transition-all duration-300
          ${
            (description === null && taskDescription === '') ||
            description === undefined ||
            description === taskDescription
              ? 'opacity-0 h-0'
              : 'opacity-100 h-full mt-1 mb-1'
          }
          `}
      >
        <button
          disabled={description === taskDescription}
          onClick={handleApplyDescriptionClick}
          className='text-sm text-stone-600 py-1 px-3 rounded-md
          bg-emerald-100 border border-emerald-300 
          hover:bg-emerald-300 hover:border-emerald-500 hover:text-black 
           focus:outline-none focus:shadow-outline'
        >
          Apply description
        </button>
        <button
          disabled={description === taskDescription}
          onClick={handleCancelDescriptionClick}
          className='
            text-sm bg-red-100 border border-red-300 text-stone-600 py-1 px-3 ml-1 rounded-md
            hover:bg-red-300 hover:border-red-500 hover:text-white  
            focus:outline-none focus:shadow-outline'
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
