import {
  faCodeBranch,
  faCodePullRequest,
  faScissors,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { stopTransportation } from '../../state/transportationSlice';
import shortenText from '../../utils/shortenText';

const Transportation = () => {
  const transportationData = useSelector((state) => state.transportation);
  const { taskId, transportationType, parentReplacedId } = transportationData;
  const tasksData = useSelector((state) => state.tasks);
  const { title = '', parents = [] } = tasksData[[taskId]] || {};

  const shortenedTitle = shortenText(title, 'transportation-tooltip');
  const shortenedReoplacedTitle = parentReplacedId
    ? shortenText(tasksData[parentReplacedId].title, 'transportation-tooltip')
    : null;
  const hasParents = parents.length > 0;

  const dispatch = useDispatch();

  const handleCancelTransportation = () => {
    dispatch(stopTransportation());
  };
  return (
    <div
      className={`
      absolute left-1/2 transform -translate-x-1/2 z-20 w-max max-w-[80%] lg:max-w-[66%] 2xl:max-w-[50%]
      pl-0 pr-0 rounded-md  shadow-md  border text-sm
      flex items-center
      ${
        transportationType === 1
          ? 'bg-emerald-300 border-emerald-400'
          : 'bg-orange-200 border-orange-300 '
      }
      ${taskId ? 'animate-slide-up-bounce' : 'hidden'}
      `}
    >
      <FontAwesomeIcon
        icon={transportationType === 1 ? faCodePullRequest : faScissors}
        className=' pl-3 pr-3 text-lg'
      />
      <span className='pt-2 pb-2'>
        {transportationType === 1 && (
          <>
            Make
            <span
              className={` 
                bg-emerald-400 rounded pl-1 pr-1 ml-1 mr-1 italic 
                ${shortenedTitle.isLong ? 'cursor-pointer' : ''} 
              `}
              {...shortenedTitle.tooltip}
            >
              {shortenedTitle.value}
            </span>
            a subtask of a another task
            {hasParents ? (
              <>
                , while keeping it a subtask of{' '}
                <span
                  className='border-b border-dashed border-black cursor-pointer'
                  data-tooltip-id='transportation-tooltip'
                  data-tooltip-html={parents
                    .map((p) => tasksData[p].title)
                    .join('<br>')}
                >
                  {parents.length} other task{parents.length > 1 ? 's' : ''}
                </span>
              </>
            ) : (
              ''
            )}
            .
          </>
        )}
        {transportationType === 2 && (
          <>
            Move
            <span
              className={` bg-orange-400 rounded pl-1 pr-1 mr-1 ml-1 italic ${
                shortenedTitle.isLong ? 'cursor-pointer' : ''
              }`}
              {...shortenedTitle.tooltip}
            >
              {shortenedTitle.value}
            </span>
            to another task by removing it from
            <span
              className={`
                bg-orange-400 rounded pl-1 pr-1 ml-1 italic 
                ${shortenedReoplacedTitle.isLong ? 'cursor-pointer' : ''}`}
              {...shortenedReoplacedTitle.tooltip}
            >
              {shortenedReoplacedTitle.value}
            </span>
            {parents.length - 1 > 0 ? (
              <>
                , while keeping it a subtask of{' '}
                <span
                  className='border-b border-dashed border-black cursor-pointer'
                  data-tooltip-id='transportation-tooltip'
                  data-tooltip-html={parents
                    .filter((p) => p !== parentReplacedId)
                    .map((p) => tasksData[p].title)
                    .join('<br>')}
                >
                  {parents.length - 1} other task
                  {parents.length - 1 > 1 ? 's' : ''}
                </span>
              </>
            ) : (
              ''
            )}
            .
          </>
        )}
      </span>
      <div className='pl-3 pr-3'>
        <button
          onClick={handleCancelTransportation}
          data-tooltip-id='transportation-tooltip'
          data-tooltip-content='Cancel operation'
        >
          <FontAwesomeIcon
            icon={faXmark}
            className='
         hover:bg-red-500 hover:text-white border border-black rounded-full text-lg p-1 w-3 h-3  '
          />
        </button>
      </div>
      <Tooltip id='transportation-tooltip' />
    </div>
  );
};

export default Transportation;
