import { createSlice } from '@reduxjs/toolkit';

const generateId = () => '_' + Math.random().toString(36).substr(2, 9);

const columnsSlice = createSlice({
  name: 'columns',
  initialState: {},
  reducers: {
    fillColumns: (state, action) => {
      console.log(state);
      console.log(action.payload.columns);
      // state = action.payload.columns;
      Object.assign(state, action.payload.columns);
      console.log(state);
    },
    moveTask: (state, action) => {
      const { source, destination } = action.payload;

      const destinationId = destination.droppableId;
      if (!state[destinationId]) {
        state[destinationId] = []; // Initialize with default value
      }

      const sourceColumn = state[source.droppableId];
      const destColumn = state[destinationId];
      const [movedTask] = sourceColumn.splice(source.index, 1);
      destColumn.splice(destination.index, 0, movedTask);
      console.log('MOVE TASK: ' + JSON.stringify(state));
    },
    addTaskToColumn: (state, action) => {
      const { columnId, taskId } = action.payload;
      if (!state[columnId]) {
        state[columnId] = []; // Initialize with default value
      }
      state[columnId].push(taskId);
    },
    removeTaskFromColumn: (state, action) => {
      const { columnId, taskId } = action.payload;
      if (!state[columnId]) {
        state[columnId] = []; // Initialize with default value
      }
      const index = state[columnId].indexOf(taskId);
      if (index !== -1) {
        state[columnId].splice(index, 1);
      }
    },
  },
});

export const { fillColumns, moveTask, addTaskToColumn, removeTaskFromColumn } =
  columnsSlice.actions;

export default columnsSlice.reducer;

/*
De urmatoarea forma:
'title' si 'subtitle' optional, doar daca nu sunt auto generate gen days, weeks, months, years.

const initialItems = {
    'w-30-2024': {
      tasks: [
        { id: generateId(), title: 'Item 1' },
        { id: generateId(), title: 'Item 2 hey' },
        { id: generateId(), title: 'Item 3' },
      ],
    },
    'd-24-7-2024': {
      tasks: [
        { id: generateId(), title: 'Item 4' },
        { id: generateId(), title: 'Item 5' },
        { id: generateId(), title: 'Item 6' },
      ],
    },
  }
*/
