import {
  closeDatePicker,
  closeDropDownMenu,
  openDatePicker,
} from '../state/dropDownMenuSlice';
import { useSelector, useDispatch } from 'react-redux';
import { deleteTask, uncombineTasks } from '../api/taskApi';
import { finishLoading, setError, startLoading } from '../state/loadingSlice';
import {
  uncombineTasksByDelete,
  uncombineTasksInTasks,
  updateTaskInTasks,
} from '../state/tasksSlice';
import { removeTaskFromColumn } from '../state/columnsSlice';
import { removeTaskFromTasks } from '../state/tasksSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons/faTrashCan';
import {
  faAngleRight,
  faBan,
  faLinkSlash,
  faRotateLeft,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { updateTask } from '../api/taskApi';
import DatePicker from './DatePicker';
import { useEffect, useRef, useState } from 'react';
import { closeModal } from '../state/modalSlice';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import shortenText from '../utils/shortenText';

const DropDownMenu = () => {
  const dropDownMenuData = useSelector((state) => state.dropDownMenu);
  const { taskId, menuX, menuY, menu, datePicker } = dropDownMenuData;
  const [positionMenu, setPositionMenu] = useState(null);
  const tasksData = useSelector((state) => state.tasks);
  const {
    title,
    description,
    scheduled_date,
    scheduled_type,
    columnId,
    status,
    parents = [],
    children = [],
  } = tasksData[taskId] || {};
  const modalData = useSelector((state) => state.modal);
  const navigate = useNavigate();

  const menuRef = useRef(null);
  // console.log('dropDownMenuData: ' + dropDownMenuData.taskId);

  useEffect(() => {
    if (!menu) {
      setPositionMenu(null);
      return;
    }
    if (menuRef.current && menuX && menuY) {
      const position = {
        top: menuY,
        left: menuX,
      };
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const menuWidth = menuRef.current.offsetWidth;
      const menuHeight = menuRef.current.offsetHeight;

      // Adjust if the div goes out of the viewport to the right
      if (menuX + menuWidth > viewportWidth) {
        position.left = viewportWidth - menuWidth;
      }

      // Adjust if the div goes out of the viewport at the bottom
      if (menuY + menuHeight > viewportHeight) {
        position.top = viewportHeight - menuHeight;
      }

      position.top = position.top;
      position.left = position.left;

      // Apply the final position
      setPositionMenu(position);
      // console.log({ position });
      // console.log({ positionMenu });
    }
  }, [menu]);
  // console.log({ positionMenu });

  const { loading } = useSelector((state) => state.loading);
  const dispatch = useDispatch();

  const handleTaskDateChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const menuWidth = menuRef.current.offsetWidth;
    // console.log('Button width:', menuWidth);
    if (datePicker) {
      dispatch(closeDatePicker());
    } else {
      dispatch(
        openDatePicker({
          taskId: taskId,
          x: positionMenu.left,
          y: positionMenu.top,
          menuWidth: menuWidth,
        })
      );
    }
  };
  const handleCancelTask = async (e) => {
    e.stopPropagation();
    console.log('Cancelling/unc task: ' + taskId);
    const cancelOrUncancel = status === 2 ? 0 : 2;

    dispatch(startLoading('Cancelling task...'));

    try {
      const cancelTaskPromise = await updateTask({
        id: taskId,
        data: { status: cancelOrUncancel },
      });
      dispatch(
        updateTaskInTasks({ id: taskId, changes: { status: cancelOrUncancel } })
      );
      dispatch(closeDropDownMenu());
    } catch (error) {
      dispatch(
        setError({
          message: "Can't cancel task, please try again.",
          error: error,
        })
      );
    } finally {
      dispatch(finishLoading());
    }
  };
  const handleDeleteTask = async (e) => {
    e.stopPropagation();
    const confirmDelete = confirm(
      'Are you sure you want to delete this task? The hierarchies where this task is a child or a parent will be affected. The children will not have this task as a parent and the parents will not have this task as a child anymore. The operation is irreversible.'
    );
    if (confirmDelete) {
      try {
        dispatch(startLoading('Deleting task...'));
        await deleteTask(taskId);
        dispatch(uncombineTasksByDelete({ taskId: taskId }));
        dispatch(
          removeTaskFromColumn({
            columnId: columnId,
            taskId: taskId,
          })
        );
        dispatch(removeTaskFromTasks(taskId));
        dispatch(finishLoading());
        dispatch(closeDropDownMenu());
        if (taskId === modalData.content?.taskId) {
          dispatch(closeModal());
        }
      } catch (error) {
        dispatch(
          setError({
            message: "Can't delete task, please try again.",
            error: error,
          })
        );
      }
    }
  };

  const handleDetachParentClick = async (e, parentId) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Detaching parent: ' + parentId + ' from ' + taskId);
    dispatch(startLoading('Detaching parent...'));
    try {
      await uncombineTasks({
        id: taskId,
        parent_id: parentId,
      });
      dispatch(
        uncombineTasksInTasks({
          parentId: parentId,
          childId: taskId,
        })
      );
      dispatch(closeDropDownMenu());
    } catch (error) {
      dispatch(
        setError({
          message: "Can't detach parent from task, please try again.",
          error: error,
        })
      );
    } finally {
      dispatch(finishLoading());
    }
  };

  const handleOpenTaskPerspectiveClick = () => {
    dispatch(closeDropDownMenu());
    dispatch(closeModal());
    navigate('/task-perspective/' + taskId);
  };

  return (
    <div
      className={`w-full h-full absolute z-[12] overflow-hidden
    ${menu || datePicker ? 'block' : 'hidden'}`}
      onClick={() => {
        dispatch(closeDropDownMenu());
      }}
    >
      <div
        style={!positionMenu ? {} : positionMenu}
        className={`
          ${menu ? 'block' : 'hidden'}
          ${!positionMenu ? 'opacity-0' : ''}
          menu-list absolute
          rounded-md shadow-md duration-300 border-stone-300 border bg-stone-200 text-black text-sm`}
        ref={menuRef}
      >
        <button
          onClick={handleOpenTaskPerspectiveClick}
          className='flex items-center justify-between w-full p-1
           transition duration-300 hover:bg-stone-300'
        >
          <div className='flex items-center p-1'>
            <FontAwesomeIcon
              icon={faUpRightFromSquare}
              className='w-4 h-4 p-1'
            />
            <span className='p-1'>Open Perspective</span>
          </div>
        </button>

        <button
          onClick={handleTaskDateChange}
          className='flex items-center justify-between w-full rounded-t-md p-1
           transition duration-300 hover:bg-stone-300'
        >
          <div className='flex items-center p-1'>
            <FontAwesomeIcon icon={faClock} className='w-4 h-4 p-1' />
            <span className='p-1'>Change Date</span>
          </div>
          <FontAwesomeIcon
            icon={faAngleRight}
            className='text-stone-400 flex-none p-1'
          />
        </button>
        <button
          onClick={handleCancelTask}
          disabled={loading}
          className='flex items-center justify-between w-full p-1
           transition duration-300 hover:bg-stone-300'
        >
          <div className='flex items-center p-1'>
            {status === 2 ? (
              <FontAwesomeIcon icon={faRotateLeft} className='w-4 h-4 p-1' />
            ) : (
              <FontAwesomeIcon icon={faBan} className='w-4 h-4 p-1' />
            )}
            <span className='p-1'>
              {status === 2 ? 'Reactivate Task' : 'Cancel Task'}
            </span>
          </div>
        </button>
        {parents.map((parentId) => {
          const parentTitle = shortenText(
            tasksData[parentId].title,
            'dropdown-tooltip'
          );
          return (
            <button
              key={parentId}
              onClick={(e) => {
                handleDetachParentClick(e, parentId);
              }}
              disabled={loading}
              className='flex items-center justify-between w-full p-1
           transition duration-300 hover:bg-stone-300'
            >
              <div className='flex items-center p-1'>
                <FontAwesomeIcon icon={faLinkSlash} className='w-4 h-4 p-1' />
                <span className='p-1 text-left'>
                  Detach from{' '}
                  <span className='font-bold italic' {...parentTitle.tooltip}>
                    {parentTitle.value}
                  </span>
                </span>
              </div>
            </button>
          );
        })}
        <button
          onClick={handleDeleteTask}
          disabled={loading}
          className='border-t border-solid w-full border-red-400 flex items-center justify-between p-1
          bg-red-300 rounded-b-md
           transition duration-300 hover:bg-red-400'
        >
          <div className='flex items-center p-1'>
            <FontAwesomeIcon icon={faTrashCan} className='w-4 h-4 p-1' />
            <span className='p-1'>Delete Task</span>
          </div>
        </button>
      </div>
      <DatePicker />
      <Tooltip id='dropdown-tooltip' />
    </div>
  );
};

export default DropDownMenu;
