import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Task from '../components/Task/Task';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import AddTask from '../components/Task/AddTask';
import { openModal } from '../state/modalSlice';
import { Tooltip } from 'react-tooltip';
import TaskEdit from '../components/Task/TaskEdit';
import { PAGES } from '../utils/constants';
import TaskPath from '../components/Task/TaskPath';
import TaskMore from '../components/Task/TaskMore';
import TaskDate from '../components/Task/TaskDate';
import { RootState } from '../state/store';

// Define a single interface for the columns structure
interface TaskColumns extends Array<Array<{ [key: string]: string[] }>> {} // Array of arrays of objects

export default function TaskPerspectivePage() {
  const params = useParams();
  const { id } = params;
  const tasksData = useSelector((state: RootState) => state.tasks);

  const navigate = useNavigate();
  useEffect(() => {
    if (!id || !tasksData[id]) {
      navigate('/'); // Handle the case where id is not provided
    }
  }, [id, tasksData, navigate]);

  if (!id || !tasksData[id]) {
    return <>Missing task.</>;
  }

  const [columns, setColumns] = useState<TaskColumns>([]); // Specify the type
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null); // Updated type to string | null
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);
  const [hoveredSectionId, setHoveredSectionId] = useState<string | null>(null); // Updated type to string | null
  const [selectedTaskPath, setSelectedTaskPath] = useState<string[]>([]); // Specify the type as string[]
  const [hoveredTaskPath, setHoveredTaskPath] = useState<string[]>([]);

  const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({}); // Define the type for sectionRefs

  const taskRefs = useRef<{ [key: string]: HTMLElement | null }>({}); // Define the type for taskRefs

  const dispatch = useDispatch();

  function buildTaskHierarchy(rootId: string) {
    const queue: { id: string; parentId: string; level: number }[] = [];
    const columns: TaskColumns = []; // Adjusted type definition

    // Start with the root tasks
    const rootChildren = tasksData[rootId]?.children || [];
    rootChildren.forEach((childId) =>
      queue.push({ id: childId, parentId: rootId, level: 0 })
    );

    while (queue.length > 0) {
      const current = queue.shift(); // Get the current item
      if (!current) {
        return;
      } // Exit if current is undefined
      const { id, parentId, level } = current; // Destructure safely

      // Ensure the current level exists in the columns array
      if (!columns[level]) {
        columns[level] = []; // Initialize as an empty array
      }

      // Check if there's already a group with this parentId at the current level
      let parentGroup = columns[level].find((group) => parentId in group);

      if (!parentGroup) {
        // If not, create a new group for this parentId
        parentGroup = { [parentId]: [] };
        columns[level].push(parentGroup);
      }

      // Add the current task id to its parent's group
      parentGroup[parentId].push(id);

      // Ensure children are processed in the order they appear
      const children = tasksData[id]?.children || [];
      children.forEach((childId, index) => {
        queue.splice(index, 0, { id: childId, parentId: id, level: level + 1 });
      });

      // If no children, ensure it's represented at the next level with an empty array
      if (
        children.length === 0 &&
        (!columns[level + 1] ||
          !columns[level + 1].find((group) => id in group))
      ) {
        if (!columns[level + 1]) {
          columns[level + 1] = [];
        }
        columns[level + 1].push({ [id]: [] });
      }
    }

    if (columns.length === 0) {
      columns[0] = [];
    }
    console.log({ columns });
    return columns;
  }

  // Call the function with the root ID
  //TODO: baga asta in useeffect.
  useEffect(() => {
    if (id) {
      // Check if id is defined
      setColumns(buildTaskHierarchy(id) || []); // Provide a default empty array
    }
  }, [tasksData, id]);

  function findPathIterative(tasks: TaskColumns, targetId: string): string[] {
    if (tasks.length === 0) {
      return [];
    }
    const stack: {
      parentId: string;
      childIds: string[];
      path: string[];
      level: number;
    }[] = []; // Initialize the stack with the correct type

    // Initialize the stack with the first level of tasks
    for (const group of tasks[0]) {
      const [parentId, childIds] = Object.entries(group)[0];
      stack.push({ parentId, childIds, path: [parentId], level: 0 });
    }

    while (stack.length > 0) {
      const current = stack.pop(); // Get the current item
      if (!current) {
        return []; // Exit if current is undefined
      }
      const { parentId, childIds, path, level } = current; // Destructure safely

      if (childIds.includes(targetId)) {
        // Target found, return the full path including the target's parent
        return path;
      }

      // Push the children to the stack, adding the current parentId to the path
      if (level + 1 < tasks.length) {
        const nextLevel = tasks[level + 1];
        for (const group of nextLevel) {
          const [childParentId, nextChildIds] = Object.entries(group)[0];
          if (childIds.includes(childParentId)) {
            stack.push({
              parentId: childParentId,
              childIds: nextChildIds,
              path: [childParentId, ...path],
              level: level + 1,
            });
          }
        }
      }
    }

    return []; // Return null if targetId is not found
  }

  // Scroll to the selected section when it changes
  useEffect(() => {
    if (!selectedTaskId) {
      setSelectedTaskPath([]);
    }

    const scrollToElementIfNotVisible = async (
      element: HTMLElement | null,
      index: number
    ) => {
      if (element) {
        const rect = element.getBoundingClientRect();

        // Check if the element is within the viewport
        const isVisible =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);

        // If the element is not visible, scroll to it
        if (!isVisible) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: 'instant',
              block: 'center',
            });
            console.log('Scrollat ' + index);
          }, index * 0); // Adjust the delay (500ms) as needed
        }
      }
    };

    if (selectedTaskId && sectionRefs.current[selectedTaskId]) {
      const path = findPathIterative(columns, selectedTaskId);
      setSelectedTaskPath(path);
      console.log({ selectedTaskPath });
      console.log({ selectedTaskId });
      path?.forEach((id, index) => {
        if (id && taskRefs.current[id]) {
          scrollToElementIfNotVisible(taskRefs.current[id], index + 1);
        }
      });
      scrollToElementIfNotVisible(sectionRefs.current[selectedTaskId], 0);
    }
  }, [selectedTaskId]);

  useEffect(() => {
    if (hoveredSectionId) {
      let sectionPath: string[] = [hoveredSectionId]; // Explicitly type sectionPath as string[]
      sectionPath = sectionPath.concat(
        findPathIterative(columns, hoveredSectionId)
      );
      setHoveredTaskPath(sectionPath);
    } else {
      setHoveredTaskPath([]);
    }
  }, [hoveredSectionId]);

  const handleModalClick = () => {
    dispatch(openModal({ taskId: id }));
  };

  return (
    <div className='w-full h-full'>
      <div className='space flex h-full w-full content-stretch relative '>
        {columns.map((column, index) => {
          return (
            <div
              key={index}
              className={`overflow-y-scroll h-full no-scrollbar flex-1 flex flex-col p-1 pt-0 pb-0 pl-0 pr-0 
              ${index === 0 ? 'min-w-72 lg:min-w-96' : 'min-w-72'} `}
            >
              {index === 0 ? (
                <>
                  <div className='flex flex-row ml-1 mt-1'>
                    <button
                      onClick={handleModalClick}
                      data-tooltip-id='task-perspective-tooltip'
                      data-tooltip-delay-show={500}
                      data-tooltip-content='Open task in modal'
                      className='h-7 w-7 flex items-center justify-center rounded-full 
              transition duration-300 hover:bg-stone-400'
                    >
                      <FontAwesomeIcon icon={faWindowRestore} />
                    </button>
                    <TaskMore taskId={id} />
                  </div>
                  <div className='p-3 pb-1 grow italic text-stone-400 text-sm sm:text-xs'>
                    <TaskDate taskId={id} />
                    <TaskPath taskId={id} />
                  </div>
                  <TaskEdit taskId={id} />
                  {column.length === 0 && (
                    <div className='px-3 pb-1 flex flex-col'>
                      <div
                        className='italic text-stone-500 text-lg border border-stone-400 border-dashed p-1 px-2 mb-1 rounded-lg w-fit
                      bg-stone-100 hover:bg-stone-200 transition duration-300'
                      >
                        Add subtasks to start increasing the task's perspective!
                      </div>
                      <AddTask addTaskData={{ parent_id: id }} />
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
              <div
                className={`index===?'':'overflow-y-scroll h-full no-scrollbar'`}
              >
                {column.map((section, index) => {
                  //section is {key:value}, get the key and value in 2 variables called sectionId and tasks
                  const sectionId = Object.keys(section)[0];
                  const tasks = section[sectionId];

                  return (
                    tasksData[sectionId] && (
                      <div
                        key={index}
                        ref={(el) => (sectionRefs.current[sectionId] = el)}
                        onMouseEnter={() => setHoveredSectionId(sectionId)}
                        onMouseLeave={() => setHoveredSectionId(null)}
                        className={`transition duration-300   mb-1 
                        ${selectedTaskId === sectionId ? 'bg-stone-200' : ''}
                        ${hoveredTaskId === sectionId ? 'bg-stone-100' : ''}
                        
                        ${
                          (!tasksData[sectionId].children ||
                            tasksData[sectionId].children.length === 0) &&
                          sectionId !== selectedTaskId
                            ? 'hidden'
                            : ''
                        }
                        `}
                      >
                        <div
                          className={`text-xs font-semibold text-stone-400 mb-1 pl-3 pr-1 pt-1 
                          ${id === sectionId ? 'hidden' : ''}
                          `}
                        >
                          {tasksData[sectionId].title.slice(0, 18)}
                        </div>
                        {tasks.map((taskId, index) => {
                          return (
                            tasksData[taskId] && (
                              <div
                                key={index}
                                ref={(el) => (taskRefs.current[taskId] = el)}
                                className={`group flex pl-3 pr-1
                              ${selectedTaskId === taskId ? 'bg-stone-200' : ''}
                              ${
                                selectedTaskPath?.includes(taskId)
                                  ? 'bg-stone-200'
                                  : ''
                              }
                              ${
                                hoveredTaskPath?.includes(taskId)
                                  ? 'bg-stone-100'
                                  : ''
                              }
                              
                            `}
                                onMouseEnter={() => setHoveredTaskId(taskId)}
                                onMouseLeave={() => setHoveredTaskId(null)}
                              >
                                <div className='w-full'>
                                  <Task
                                    key={taskId}
                                    taskId={taskId}
                                    taskNestLevel={0}
                                    comesFromParent={sectionId}
                                    space={PAGES.TASKPERSPECTIVE}
                                    childrenClickCallback={() => {
                                      setSelectedTaskId((prevId) =>
                                        prevId === taskId ? null : taskId
                                      );
                                    }}
                                  />
                                </div>
                                {tasksData[taskId].children?.length > 0 ? (
                                  <button
                                    onClick={() =>
                                      setSelectedTaskId((prevId) =>
                                        prevId === taskId ? null : taskId
                                      )
                                    }
                                    data-tooltip-id='task-tooltip'
                                    data-tooltip-content='Show subtasks'
                                    data-tooltip-delay-show={1500}
                                    className='pl-2 pr-2 hover:bg-stone-100  rounded-md duration-300 transition'
                                  >
                                    <FontAwesomeIcon icon={faAngleRight} />
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      setSelectedTaskId((prevId) =>
                                        prevId === taskId ? null : taskId
                                      )
                                    }
                                    data-tooltip-id='task-tooltip'
                                    data-tooltip-content='Add subtasks'
                                    data-tooltip-delay-show={1500}
                                    className='pl-2 pr-2 text-stone-200/70 group-hover:text-stone-500 hover:bg-stone-100  rounded-md duration-300 transition'
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                  </button>
                                )}
                              </div>
                            )
                          );
                        })}
                        <div
                          className='px-3 pb-1 '
                          onClick={() =>
                            setSelectedTaskId(
                              (prevId) =>
                                prevId === sectionId ? null : sectionId
                              // Fa sa se centreze si taskul selectat. Doar aici, ca altfel nu vrei sa fie centrat ca-ti fuge, dar aici esti pe add task si selectezi taskul de dinainte si nu stie sa-l centreze. Centreaza doar de aici "manual".
                            )
                          }
                        >
                          <AddTask
                            addTaskData={{ parent_id: sectionId }}
                            focusInput={
                              selectedTaskId === sectionId &&
                              tasksData[selectedTaskId].children?.length === 0
                            }
                          />
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <Tooltip id='task-perspective-tooltip' />
    </div>
  );
}
