import { Draggable } from '@hello-pangea/dnd';
import Task from './Task';

function TaskDraggable({
  taskId,
  index,
  space,
}: {
  taskId: string;
  index: number;
  space: string;
}) {
  return (
    <Draggable key={taskId} draggableId={taskId} index={index}>
      {(provided, snapshot) => (
        <Task
          provided={provided}
          snapshot={snapshot}
          taskId={taskId}
          space={space}
          taskNestLevel={0}
        />
      )}
    </Draggable>
  );
}

export default TaskDraggable;
