import { startOfWeek, addWeeks, format } from 'date-fns';

function createUTCDate(year, month, day) {
  return format(new Date(year, month, day, 0, 0, 0), 'yyyy-MM-dd HH:mm');
}
function getDateFromWeek(year, weekNumber) {
  // Start with January 1st of the given year
  const startOfYear = createUTCDate(year, 0, 1);

  // Get the first Monday of the year
  const firstMonday = startOfWeek(startOfYear, { weekStartsOn: 1 });

  // Calculate the date of the Monday of the given week
  return format(addWeeks(firstMonday, weekNumber - 1), 'yyyy-MM-dd HH:mm');
}

function idToScheduled(id) {
  const splittedId = id.split('-');
  let scheduledType = splittedId[0];
  const convertedId = {
    scheduled_type: scheduledType.toUpperCase(),
    scheduled_date: '',
  };

  if (scheduledType === 'd') {
    convertedId.scheduled_date = createUTCDate(
      splittedId[3],
      splittedId[2] - 1,
      splittedId[1]
    );
  } else if (scheduledType === 'w') {
    convertedId.scheduled_date = getDateFromWeek(splittedId[2], splittedId[1]);
  } else if (scheduledType === 'm') {
    convertedId.scheduled_date = createUTCDate(
      splittedId[2],
      splittedId[1] - 1,
      1
    );
  } else if (scheduledType === 'y') {
    convertedId.scheduled_date = createUTCDate(splittedId[1], 0, 1);
  } else if (scheduledType === 'l') {
    delete convertedId.scheduled_date;
  }

  convertedId.scheduled_type = scheduledType.toUpperCase();
  return convertedId;
}

export { idToScheduled };
