import { useEffect } from 'react';

const useKeyboardShortcuts = (shortcuts) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key, metaKey, ctrlKey } = event;

      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const isWindows =
        navigator.platform.toUpperCase().indexOf('WIN') >= 0 ||
        navigator.platform.toUpperCase().indexOf('LINUX') >= 0;

      shortcuts.forEach(({ keyCombo, action }) => {
        if (
          (isMac && metaKey && key.toLowerCase() === keyCombo.toLowerCase()) ||
          (isWindows && ctrlKey && key.toLowerCase() === keyCombo.toLowerCase())
        ) {
          event.preventDefault();
          action();
        }
      });
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [shortcuts]);
};

export default useKeyboardShortcuts;
