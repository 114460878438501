import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import shortenText from '../../utils/shortenText';
import { useDispatch } from 'react-redux';
import { openModal } from '../../state/modalSlice';

export default function TaskPath({ taskId }: { taskId: string }) {
  const tasksData = useSelector((state: RootState) => state.tasks);
  const { parents } = tasksData[taskId] || {};

  const dispatch = useDispatch();

  const getParentPath = (taskId: string) => {
    if (!tasksData[taskId]?.parents || tasksData[taskId].parents.length === 0) {
      return [taskId];
    }
    const parentPaths: string[] = tasksData[taskId].parents.flatMap(
      (parentId: string) => getParentPath(parentId)
    );
    return [...parentPaths, taskId];
  };

  const handleParentClick = (parentId: string) => {
    dispatch(openModal({ taskId: parentId }));
  };

  return (
    parents &&
    parents.map((taskId) => {
      const paths = getParentPath(taskId);

      return (
        <div key={taskId} className='flex flex-row items-start rounded-md'>
          <FontAwesomeIcon
            icon={faCodeBranch}
            flip='vertical'
            className='mr-1 text-stone-300 h-4 w-4 mt-1'
          />
          <div>
            {paths.map((id, index) => {
              const pathTitle = shortenText(
                tasksData[id].title,
                'task-tooltip'
              );
              return (
                <span key={id}>
                  <button
                    onClick={() => handleParentClick(id)}
                    {...pathTitle.tooltip}
                    className='
                p-1 pl-2 pr-2 rounded-md
              transition duration-300 hover:bg-stone-300 hover:text-stone-700'
                  >
                    {pathTitle.value}
                  </button>
                  {index < paths.length - 1 && (
                    <span className='text-stone-300 cursor-default'>
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        className='mr-1 ml-1'
                      />
                    </span>
                  )}
                </span>
              );
            })}
          </div>
        </div>
      );
    })
  );
}
