import { argon2id } from 'hash-wasm';
import { XChaCha20Poly1305 } from '@stablelib/xchacha20poly1305';

export const encryptTitleOrDescription = async (
  content: string,
  masterKey: string,
  encryptedItemsKeys: Record<string, string>,
  encryptedItemsKeyId: string
) => {
  const itemsKeyEncrypted = encryptedItemsKeys[encryptedItemsKeyId];
  const itemsKey = decryptText(itemsKeyEncrypted, masterKey);
  const contentItemKey = await generateSeed();
  const contentItemKeyEncrypted = encryptText(contentItemKey, itemsKey); //send api
  const contentEncrypted = encryptText(content, contentItemKey); //send api

  return {
    contentEncrypted,
    contentItemKeyEncrypted,
  };
};

export const generateSeed = async () => {
  const array = new Uint8Array(32); // 256 bits / 8 = 32 bytes
  window.crypto.getRandomValues(array);
  // return Array.from(array)
  //   .map((byte) => byte.toString(16).padStart(2, '0'))
  //   .join('');
  return bytesToHex(array);
};

const generateAccountSalt = async (identifier: string, seed: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(`${identifier}:${seed}`);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
};

export const generatePasswordKeys = async (
  email: string,
  password: string,
  pw_once_from_api: string | null = null
) => {
  const pw_nonce = pw_once_from_api ? pw_once_from_api : await generateSeed();
  const accountSalt = await generateAccountSalt(email, pw_nonce);

  const derivedKey = await argon2id({
    password: password,
    salt: accountSalt,
    parallelism: 1,
    iterations: 12,
    memorySize: 65536, // use 512KB memory
    hashLength: 64, // output size = 32 bytes
    outputType: 'hex', // return standard encoded string containing parameters needed to verify the key
  });

  const keyParams = {
    pw_nonce,
    masterKey: derivedKey.slice(0, derivedKey.length / 2), // First half
    serverPassword: derivedKey.slice(derivedKey.length / 2), // Second half
  };
  return keyParams;
};

function hexToBytes(hex: string) {
  const bytes = new Uint8Array(hex.length / 2);
  for (let i = 0; i < hex.length; i += 2) {
    bytes[i / 2] = parseInt(hex.substr(i, 2), 16);
  }
  return bytes;
}

export const bytesToHex = (bytes: Uint8Array) => {
  return Array.from(bytes)
    .map((byte) => byte.toString(16).padStart(2, '0')) // Convert each byte to a 2-digit hex value
    .join(''); // Join all hex values into a single string
};

// Encode Uint8Array to Base64 string
function encodeBase64(array: Uint8Array) {
  // Specify the type of 'array'
  let binary = '';
  const bytes = new Uint8Array(array);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

// Decode Base64 string to Uint8Array
function decodeBase64(base64: string): Uint8Array {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export const encryptText = (text: string, password: string) => {
  const passwordBytes = hexToBytes(password);
  // console.log({ password, passwordBytes });
  const encodedText = new TextEncoder().encode(text);
  const nonce = window.crypto.getRandomValues(new Uint8Array(24));

  const xchacha = new XChaCha20Poly1305(passwordBytes);
  const ciphertext = xchacha.seal(nonce, encodedText);

  const nonceBase64 = encodeBase64(nonce);
  const encryptedTextBase64 = encodeBase64(ciphertext);

  return `${nonceBase64}:${encryptedTextBase64}`;
};

// Decode a Uint8Array to a string (UTF-8)
function decodeUTF8(bytes: Uint8Array): string {
  return new TextDecoder().decode(bytes);
}

export const decryptText = (text: string, key: string) => {
  const [nonceBase64, cipherBase64] = text.split(':');
  const nonceBytes = decodeBase64(nonceBase64);
  const ciphertextBytes = decodeBase64(cipherBase64);
  const keyBytes = hexToBytes(key);

  const xchacha = new XChaCha20Poly1305(keyBytes);

  // Decrypt the message
  const decryptedMessageBytes = xchacha.open(nonceBytes, ciphertextBytes);

  if (!decryptedMessageBytes) {
    throw new Error('Decryption failed. Wrong password or corrupted data.');
  }

  // Convert decrypted bytes back to a UTF-8 string
  const decryptedMessage = decodeUTF8(decryptedMessageBytes);

  return decryptedMessage;
};

// --------------------------------------------------
// ==================================================
// --------------------------------------------------

// Encode a string to a Uint8Array (UTF-8)
function encodeUTF8(str: string): Uint8Array {
  return new TextEncoder().encode(str);
}
